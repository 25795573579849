import {Component} from "react";
import {Trans} from "react-i18next";
import "./ForModels.scss"
import ScrollAnimation from "react-animate-on-scroll";
import {getImage} from "../../../imagePreloader";

interface ForModelsProps {
    images: any
}

interface ForModelsState {
    forLeftStyle: number
    modelLeftStyle: number
}

export default class ForModels extends Component<ForModelsProps, ForModelsState> {

    constructor(props: ForModelsProps) {
        super(props);
        this.state = {forLeftStyle: 0, modelLeftStyle: 0};
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.innerWidth < 768) {
            this.setState({
                forLeftStyle: Math.max(-1 * Math.max(window.innerWidth / 4) + 0.05 * window.scrollY),
                modelLeftStyle: Math.max(Math.max(window.innerWidth / 5) - 0.05 * window.scrollY)

            })
        } else {
            this.setState({
                forLeftStyle: Math.max(-1 * Math.max(window.innerWidth / 7) + 0.1 * window.scrollY),
                modelLeftStyle: Math.max(Math.max(window.innerWidth / 4) - 0.1 * window.scrollY)
            })
        }

    }

    render() {
        return (
            <div className="models" id="models">
                <div className="models__header">
                    <h2 className="models__header-for" style={{
                        transform: `translate(${this.state.forLeftStyle}px)`
                    }}>For</h2>
                    <div className="models__header-images">
                        <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                            <img src={getImage(this.props.images, "forModel")} className="models__header-images-models" alt="Models"/>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                            <img src={getImage(this.props.images, "forPhotoshooting")} className="models__header-images-photo-shooting" alt="Shooting"/>
                        </ScrollAnimation>
                    </div>
                    <h2 className="models__header-model" style={{
                        transform: `translate(${this.state.modelLeftStyle}px)`
                    }}>Model</h2>
                </div>
                <div className="models__info">
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                        <h4 className="models__info-title">Model</h4>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="animate_visible" delay={200} animateOnce={true}>
                        <ul className="models__info-list">
                            <li className="models__info-list-item"><Trans i18nKey="models.first.message"/></li>
                            <li className="models__info-list-item"><Trans i18nKey="models.second.message"/></li>
                            <li className="models__info-list-item"><Trans i18nKey="models.third.message"/></li>
                            <li className="models__info-list-item"><Trans i18nKey="models.fourth.message"/></li>
                            <li className="models__info-list-item"><Trans i18nKey="models.fifth.message"/></li>
                            <div className="thread">
                                <div className="thread-inner"/>
                            </div>
                        </ul>
                    </ScrollAnimation>
                </div>
            </div>
        );
    }
}