import {Component} from "react";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import ambassadors from "./ambassador.json"
import "./Ambassador.scss"

interface AmbassadorState {
    amLeft: number
}

export default class Ambassador extends Component<{}, AmbassadorState> {

    constructor(props: any) {
        super(props);
        this.state = {amLeft: 0}
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.innerWidth < 768) {
            this.setState({amLeft: Math.max(Math.max(window.innerWidth / 2) - 0.05 * window.scrollY)})
        } else {
            this.setState({amLeft: Math.max(Math.max(window.innerWidth / 3) - 0.1 * window.scrollY)})
        }
    }

    calculateVideoHeight = () => {
        if (window.innerWidth < 768) {
            return window.innerHeight / 4;
        } else {
            return window.innerHeight / 1.75;
        }
    }

    getContent() {
        return ambassadors.map(ambassador => (
            <div className="ambassador__content-item">
                <a href={`https://instagram.com/${ambassador.link}`} target="_blank" rel="noreferrer">
                    <img className="ambassador__content-item-picture"
                         src={`/ambassadors/ambassador-${ambassador.link}.jpg`}/>
                    <div className="ambassador__content-item-about">
                        <img className="ambassador__content-item-about-img"
                             src={`/ambassadors/avatar-${ambassador.link}.jpg`}/>
                        <div className="ambassador__content-item-data">
                            <h6 className="ambassador__content-item-name">{ambassador.name}</h6>
                            <p className="ambassador__content-item-link">{ambassador.link}</p>
                            <h6 className="ambassador__content-item-sub">{ambassador.about}</h6>
                        </div>
                    </div>
                </a>
            </div>
        ));
    }

    render() {
        return (
            <div className="ambassador" id="ambassador">
                <div className="ambassador__content">
                    <div className="ambassador__content--first-column">
                        <div className="ambassador__content--first-column-background"/>
                        <div className="ambassador__header">
                            <h2 className="ambassador__header-background"
                                style={{transform: `translate(${this.state.amLeft}px)`}}>Ambassador</h2>
                            <h4 className="ambassador__header-title">Ambassador</h4>
                        </div>
                        <div className="ambassador__content-video">
                            <iframe src="https://www.youtube.com/embed/LmP3C5TA4U8" frameBorder="0" width="100%"
                                    title="Youtube video" height={this.calculateVideoHeight()}/>
                        </div>
                        <div className="ambassador__content-steps">
                            <h4 className="ambassador__content-steps-title">Four steps</h4>
                            <div className="ambassador__content-steps-list">
                                <span className="ambassador__content-steps-list-line"/>
                                {Array.from(Array(4).keys()).map(index => (
                                    <span key={index} className="ambassador__content-step">
                                        <Trans i18nKey={`ambassador.steps.${index + 1}`}/>
                                    </span>
                                ))}
                            </div>
                        </div>
                        <h3 className="ambassador__content-credo">#iBrandFace</h3>
                    </div>
                    <div className="ambassador__content--second-column">
                        <div className="ambassador__content--second-column-background"/>
                        <Slider
                            className="ambassador__slider"
                            slidesToShow={1}
                            dotsClass="ambassador__slider-dots"
                            slidesToScroll={1}
                            arrows={true}
                            infinite={false}
                            dots={true}>
                            {this.getContent()}
                        </Slider>
                        <div className="ambassador__content-item ambassador__content-item--you-next">
                            <h4>You</h4>
                            <h6>next</h6>
                        </div>
                        <Link to="commercial-offer" className="ambassador__content-button">
                            <span>GET STARTED!</span>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}