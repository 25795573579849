import React, {Component} from "react";
import {Trans} from "react-i18next";
import {getImage} from "../../../imagePreloader";
import "./Major.scss"
import {ComingSoonModal} from "../../modal/ComingSoon";

interface MajorProps {
    images: any
}

interface MajorState {
    playMarketHover: boolean
    appStoreHover: boolean
    animate: string
    showModal: boolean
}

export class Major extends Component<MajorProps, MajorState> {

    constructor(props: MajorProps) {
        super(props);

        this.state = {
            playMarketHover: false,
            appStoreHover: false,
            showModal: false,
            animate: ""
        }
    }

    componentDidMount() {
        this.setState({animate: "animate"})
    }

    render() {
        const {images} = this.props;
        return (
            <div className="major">
                <div className="major__container">
                    <div className="major__container-background"
                         style={{backgroundImage: `URL(${getImage(images, "majorBackground")})`}}/>
                    <div className={`major__logo ${this.state.animate}`}>
                        <img className="major__logo-image" src={getImage(images, "majorBrandLogo")}
                             alt="iBrand - make your own brand"/>
                        <h6 className="major__logo-text"><Trans i18nKey="major.title"/></h6>
                    </div>
                    <div className={`major__content ${this.state.animate}`}>
                        <img className="major__content-image major__content-image--mobile-hidden"
                             src={getImage(images, "majorModels")}
                             alt="Models screen"/>
                        <img className="major__content-image" src={getImage(images, "majorMobile")} alt="Mobile app"/>
                        <img className="major__content-image major__content-image--mobile-hidden"
                             src={getImage(images, "majorProducer")}
                             alt="Producers screen"/>
                        <div className={`major__download-links ${this.state.animate}`}>
                            <a className="major__download-link"
                               href="https://apps.apple.com/ua/app/ibrand-models-castings-events/id1608781339"
                               target="_blank" rel="noreferrer" onMouseOver={() => this.setState({appStoreHover: true})}
                               onMouseLeave={() => this.setState({appStoreHover: false})}>
                                <img
                                    src={getImage(images, this.state.appStoreHover ? "appStoreHover" : "appStore")}
                                    alt="Download from App Store"/>
                            </a>
                            <a className="major__download-link" target="_blank" rel="noreferrer"
                               href="https://play.google.com/store/apps/details?id=com.ibrandapp"
                               onMouseOver={() => this.setState({playMarketHover: true})}
                               onMouseLeave={() => this.setState({playMarketHover: false})}>
                                <img
                                    src={getImage(images, this.state.playMarketHover ? "playMarketHover" : "playMarket")}
                                    alt="Download from Play Market"/>
                            </a>
                        </div>
                    </div>
                </div>
                <ComingSoonModal isOpen={this.state.showModal} closeModal={() => this.setState({showModal: false})}/>
                <div className="thread">
                    <div className="thread-inner"/>
                </div>
            </div>
        );
    }
}