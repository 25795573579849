import React, {Component} from "react";
//@ts-ignore
import ReactCountryFlag from "react-country-flag"
import {Location} from "./interfaces";
import {ReactComponent as Star} from "../../../assets/home/top/star.svg";
import {ReactComponent as Female} from "../../../assets/home/top/female.svg";
import {ReactComponent as Male} from "../../../assets/home/top/male.svg";

export interface Model {
    id: string
    first_name: string
    last_name: string
    about: string
    location?: Location
    rating: number
    gender: string
    age: number
}

export class ModelCard extends Component<Model> {

    getLocation() {
        const {location} = this.props;

        if (!location) {
            return null;
        }

        return (
            <span className="top__card-info-location">
                <ReactCountryFlag countryCode={this.props.location?.country.country_code} svg/>
                {`${location.country.translation.translation}, ${location.city.translation.translation}`}
            </span>
        );

    }

    getGender() {
        if (this.props.gender === "f") {
            return <Female/>
        } else if (this.props.gender === "m") {
            return <Male/>
        }

        return null;
    }

    render() {
        return (
            <div className="top__card">
                <div className="top__card-info">
                    <div className="top__card-info-image" style={{
                        backgroundImage: `url(/profile/profile_picture/${this.props.id})`
                        // backgroundImage: `url(https://ibrandapp.com/profile/profile_picture/${this.props.id})`
                    }}/>
                    <div className="top__card-info-data">
                        <span
                            className="top__card-info-name">{`${this.props.first_name} ${this.props.last_name}`}</span>
                        <div className="top__card-info-details">
                            <span className="top__card-info-gender">{this.getGender()}</span>
                            <span className="top__card-info-age">{this.props.age} года</span>
                            {this.getLocation()}
                        </div>
                    </div>
                    <span className="top__card-info-rating">
                        <Star/>
                        {this.props.rating}
                    </span>
                </div>
                <div className="top__card-about">
                    <p className="top__card-about-text">{this.props.about}</p>
                </div>
            </div>
        );
    }
}