import {Component} from "react";
import {Trans} from "react-i18next";
import {ReactComponent as BrandText} from "../../../assets/common/iBrand-text.svg";
import {ReactComponent as BrandLogo} from "../../../assets/common/iBrandLogo.svg";
import "./Producer.scss"
import ScrollAnimation from "react-animate-on-scroll";
import {getImage} from "../../../imagePreloader";

interface ProducerProps {
    images: any
}

interface ProducerState {
    leftStyle: number
}

export class Producer extends Component<ProducerProps, ProducerState> {

    constructor(props: ProducerProps) {
        super(props);
        this.state = {leftStyle: 0}
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.innerWidth < 768) {
            this.setState({leftStyle: Math.max(-1 * Math.max(window.innerWidth / 4) + 0.05 * window.scrollY)})
        } else {
            this.setState({leftStyle: Math.max(-1 * Math.max(window.innerWidth / 5) + 0.1 * window.scrollY)})
        }
    }

    render() {
        return (
            <div className="producer" id="producer">
                <div className="producer__header">
                    <h2 className="producer__header-title" style={{
                        transform: `translateX(${this.state.leftStyle}px)`
                    }}>Producer</h2>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                        <div className="producer__header-content-box">
                        <span className="producer__header-content-text">
                            <BrandText/>
                        </span>
                            <span className="producer__header-content-logo">
                            <BrandLogo/>
                        </span>
                            <img className="producer__header-content-image"
                                 src={getImage(this.props.images, "producerImage")} alt="Producer"/>
                        </div>
                    </ScrollAnimation>
                </div>
                <div className="producer__info">
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                        <h4 className="producer__info-title">Producer</h4>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="animate_visible" delay={200} animateOnce={true}>
                        <ul className="producer__info-list">
                            <li className="producer__info-list-item"><Trans i18nKey="producer.first.message"/></li>
                            <li className="producer__info-list-item"><Trans i18nKey="producer.second.message"/></li>
                            <li className="producer__info-list-item"><Trans i18nKey="producer.third.message"/></li>
                            <li className="producer__info-list-item"><Trans i18nKey="producer.fourth.message"/></li>
                            <li className="producer__info-list-item">
                                <Trans i18nKey="producer.fifth.message"/>
                                <span className="producer__info-list-item--soon">(Coming soon)</span>
                            </li>
                            <div className="thread">
                                <div className="thread-inner"/>
                            </div>
                        </ul>
                    </ScrollAnimation>
                </div>
            </div>
        );
    }
}