import {Component} from "react";
import Modal from "react-modal";
import {Trans} from "react-i18next";
import './ComingSoon.scss'

interface ComingSoonModalProps {
    isOpen: boolean
    closeModal: () => void
}

export class ComingSoonModal extends Component<ComingSoonModalProps> {

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                style={{overlay: {zIndex: 1000}}}
                closeTimeoutMS={500}
                className="contact-us-modal coming-soon"
                contentLabel="Contact Us Modal">
                <h4 className="contact-us-title">Coming soon</h4>
                <h6><Trans i18nKey="coming.soon.modal"/></h6>
                <button className="ambassador__content-button" onClick={this.props.closeModal}>
                    <span>OK</span>
                </button>
            </Modal>
        );
    }
}