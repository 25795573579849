import React, {Component} from "react";
//@ts-ignore
import ReactCountryFlag from "react-country-flag"
import {Location} from "./interfaces";
import {ReactComponent as Star} from "../../../assets/home/top/star.svg";
import {Trans} from "react-i18next";

export interface Producer {
    id: string
    name: string
    locations: Location[]
    active_events: number
    total_events: number
    about: string
    rating: number
}

export class ProducerCard extends Component<Producer> {

    getLocation() {
        const {locations} = this.props;

        if (!locations?.length) {
            return null;
        }

        return (
            <span className="top__card-info-location">
                <ReactCountryFlag countryCode={this.props.locations[0]?.country.country_code} svg/>
                {`${locations[0].country.translation.translation}, ${locations[0].city.translation.translation}${locations.length > 1 ? this.getNumberOfOtherLocations() : ""}`}
            </span>
        );

    }

    getNumberOfOtherLocations() {
        return ` + ${this.props.locations.length - 1}`;
    }

    render() {
        return (
            <div className="top__card top__card--producer">
                <div className="top__card-info">
                    <div className="top__card-info-image" style={{
                        backgroundImage: `url(/profile/profile_picture/${this.props.id})`
                        // backgroundImage: `url(https://ibrandapp.com/profile/profile_picture/${this.props.id})`
                    }}/>
                    <div className="top__card-info-data">
                        <span className="top__card-info-name">{this.props.name}</span>
                        <div className="top__card-info-data-events">
                            <div className="top__card-info-data-events-wrapper">
                                <span><Trans i18nKey="top.events"/></span>
                                <span
                                    className="top__card-info-data-events--active">{`: ${this.props.active_events} / `}</span>
                                <span className="top__card-info-data-events--all">{this.props.active_events}</span>
                            </div>
                            <span>
                                {this.getLocation()}
                            </span>
                        </div>
                    </div>
                    <span className="top__card-info-rating">
                        <Star/>
                        5
                    </span>
                </div>
                <div className="top__card-about">
                    <p className="top__card-about-text">{this.props.about}</p>
                </div>
            </div>
        );
    }
}