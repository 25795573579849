import React, {Component} from "react";
import youtube from "./../../../assets/offer/social-media/youtube.png"
import facebook from "./../../../assets/offer/social-media/facebook.png"
import instagram from "./../../../assets/offer/social-media/instagram.png"
import tiktok from "./../../../assets/offer/social-media/tiktok.png"
import "./SocialMedia.scss"
import {Trans} from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

export default class SocialMedia extends Component {

    render() {
        return (
            <div className="offer-component offer-social-media">
                <div className="offer-social-media__text">
                    <div className="offer-component__li">
                        <span className="square"></span>
                        <div className="thread">
                            <div className="thread-inner"/>
                        </div>
                    </div>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                        <h4 className="offer-component__heading"><Trans i18nKey="offer.social.title"/></h4>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true} delay={200}>
                        <p className="offer-component__subheading">
                            <Trans i18nKey="offer.social.description"/>
                        </p>
                    </ScrollAnimation>
                </div>
                <div className="offer-social-media__links">
                    <a href="#" className="offer-social-media__link">
                        <img src={tiktok} className="offer-social-media__link-img"/>
                        <p className="offer-social-media__link-desc">Tik-Tok</p>
                    </a>
                    <a href="https://www.facebook.com/iBrandapp" className="offer-social-media__link">
                        <img src={facebook} className="offer-social-media__link-img"/>
                        <p className="offer-social-media__link-desc">Facebook</p>
                    </a>
                    <a href="https://www.youtube.com/channel/UCcVVWSuINkJZKczhoJGFVgw"
                       className="offer-social-media__link">
                        <img src={youtube} className="offer-social-media__link-img"/>
                        <p className="offer-social-media__link-desc">YouTube</p>
                    </a>
                    <a href="https://instagram.com/ibrand.app" className="offer-social-media__link">
                        <img src={instagram} className="offer-social-media__link-img"/>
                        <p className="offer-social-media__link-desc">Instagram</p>
                    </a>
                </div>
            </div>
        );
    }
}