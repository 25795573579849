import {Fragment} from "react";
import {Footer} from "../common/Footer";
import {OfferHeader} from "../offer/OfferHeader";
import './Policy.scss';
import i18n from "i18next";

export const PolicyPage = () => {

    const getPolicyContent = () => {
        switch (i18n.language) {
            case 'ua':
                return <PolicyTextUA/>;
            case 'ru':
                return <PolicyTextRu/>;
            case 'en':
            default:
                return <PolicyTextEn/>;
        }
    }

    return (
        <Fragment>
            <OfferHeader/>
            {getPolicyContent()}
            <Footer/>
        </Fragment>
    );
}


const PolicyTextUA = () => {
    return (
        <div className="policy-content">
            <h1>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</h1>
            <p>Ця політика конфіденційності стосується веб-сайту <a href="https://www.ibrandapp.com" className="a"
                                                                    target="_blank">www.ibrandapp.com</a>
                і мобільного додатку iBrand: Models Castings Events.</p>
            <h2>Як ми обробляємо будь-які персональні дані, які ви
                нам надаєте</h2>
            <p>Коли ви приєднуєтеся до веб-сайту <a href="https://www.ibrandapp.com/" className="a"
                                                    target="_blank">www.ibrandapp.com</a> і мобільного додатку
                iBrand: Models Castings Events, ми хочемо, щоб ви точно знали, що відбувається з
                вашими даними, щоб ви могли бути повністю впевнені, що є одним із наших цінних
                і дуже улюблених учасників.
                Інформація, яку ми просимо від вас, призначена лише для однієї мети: покращити
                ваш досвід і допомогти вам отримати максимум від членства на веб-сайті та
                мобільному додатку, а також використовувати нашу платформу в бізнескомерційному ключі з вашими колегами.
                Щоб забезпечити вам ще більше спокою,
                ми правильно обслуговуємо ваші дані та ніколи не передаємо їх третім особам.</p>
            <h2>Які дані ми збираємо?</h2>
            <p>Для моделей і талантів збираються ці дані :</p>
            <ul>
                <li>Ім'я та/або назва компанії</li>
                <li>Адреса електронної пошти</li>
                <li>Місцезнаходження</li>
                <li>Стать</li>
                <li>Веб-сайт або посилання на портфоліо, якщо воно у вас є</li>
                <li>Пароль, який зашифрований</li>
                <li>Дата народження</li>
                <li>Дата, коли ви зареєструвалися, ваш останній вхід, завантажені зображення,
                    підписалися на іншого користувача, сподобалося, сподобалося чи додано
                    до вибраного зображення чи іншого користувача
                </li>
                <li>Етнічна приналежність, національність, колір волосся та очей, зріст, розмір
                    взуття, розмір бюсту, розмір талії, розмір стегон
                </li>
                <li>Мови</li>
                <li>Категорії та дисципліни моделювання, які відповідають вашому образу</li>
                <li>Дата, коли ви подаєте заявку на кастинг або приймаєте/відхиляєте
                    бронювання
                </li>
                <li>Область вільного тексту під назвою &quot;Про мене&quot;, де ви можете залишити
                    будь-яку відповідну професійну інформацію про себе
                </li>
                <li>Номер телефону</li>
                <li>Посилання на соціальні мережі</li>
                <li>Завантажені зображення</li>
            </ul>
            <p>Для фотографів, брендів, модельних агентств, кастинг-директорів, рекламних агентств, продюсерські
                компанії, медіа, виставкові зали, перукарі та візажисти, PR/події, ці дані збираються:</p>
            <ul>
                <li>Ім'я та/або назва компанії</li>
                <li>Адреса електронної пошти</li>
                <li>Місцезнаходження</li>
                <li>Стать</li>
                <li>Веб-сайт або посилання на портфоліо, якщо воно у вас є</li>
                <li>Пароль, який зашифрований</li>
                <li>Дата народження</li>
                <li>Деталі ваших операцій на нашому сайті</li>
                <li>Дата, коли ви зареєструвалися, ваш останній вхід, завантажені зображення,
                    підписалися на іншого користувача, сподобалося, сподобалося чи додано
                    до вибраного зображення чи іншого користувача
                </li>
                <li>Область вільного тексту під назвою «Про мене», де ви можете залишити
                    будь-яку відповідну професійну інформацію про себе
                </li>
                <li>Номер телефону</li>
                <li>Посилання на соціальні мережі</li>
                <li>Завантажені зображення</li>
            </ul>
            <h2>З якими постачальниками ми надаємо ваші дані?</h2>
            <p>Аналітика - GTM із файлами cookie <a href="https://www.google.com/analytics/learn/privacy.html"
                                                    className="s2" target="_blank">Google Analytics</a> і даними про
                використання</p>
            <p>Маркетинг - <a href="https://www.facebook.com/about/privacy/" className="a" target="_blank">Facebook
                Pixel </a>
                - файли cookie та дані про використання</p>
            <p><a href="https://www.twilio.com/legal/privacy" target="_blank">Twilio</a> - SMS, номер телефону, дані про
                використання</p>
            <p>Доступ до стороннього облікового запису. Це компанії, які ви можете
                використовувати на нашому веб-сайті, щоб спростити певні завдання. Наприклад,
                якщо ви вирішите зареєструватися на нашому сайті через Facebook або якщо ви
                вирішите запросити своїх особистих контактів приєднатися до нашого сайту через
                вашу власну базу даних електронної пошти. </p>
            <p><a href="https://policies.google.com/privacy" className="a" target="_blank">Facebook</a> -
                Про мене, день народження, місто, адреса електронної пошти, вебсайт</p>
            <p><a href="https://policies.google.com/privacy" className="a" target="_blank">Google</a> - Контакти</p>
            <p>Захист від СПАМу -<a href="https://policies.google.com/privacy" className="s2"
                                    target="_blank">Google reCAPTCHA</a></p>
            <p>Різні типи даних, як зазначено в політиці конфіденційності служби</p>
            <p><br/></p>
            <h2>З якими сторонніми особами ми надаємо ваші дані?</h2>
            <p>Ми не передаватимемо ваші дані третім сторонам, якщо у нас немає вашого
                дозволу або це не вимагається законом.</p>
            <h2>Як довго ми зберігаємо ваші дані?</h2>
            <p>Ваші дані зберігатимуться до тих пір, поки ви є користувачем нашого сайту і не
                вимагали видалення свого облікового запису та даних.</p>
            <h2>Де зберігається?</h2>
            <p>Усі дані зберігаються на наших захищених європейських серверах.</p>
            <h2>Чи є у нас законні підстави, чому нам потрібні ці дані?</h2>
            <p>Законними причинами, чому нам потрібні ці дані, є законні інтереси компанії, як це
                встановлено GDPR (стаття 6).</p>
            <h2>Що ми робимо з цією інформацією?</h2>
            <p>Аналізуйте активність користувачів, щоб покращити наш сервіс.</p>
            <p>Забезпечити високу якість і, зрештою, корисну взаємодію з користувачами на
                нашому сайті та в спілкуванні електронною поштою.</p>
            <p>Ми можемо сповіщати вас електронною поштою, телефоном за допомогою SMS
                або Whatsapp, Facebook Messenger з важливою інформацією, що стосується
                вашого облікового запису. Моделі можуть отримувати сповіщення за допомогою
                цих каналів, якщо їх запросили на кастинг або якщо отримано нове повідомлення
                від учасника. Крім того, якщо інший користувач поставив лайк, сподобався
                зображення або підписався, або зміни та оновлення нашого сайту, рекламні
                пропозиції, опитування, поради, новини тощо. Інші користувачі, такі як фотографи,
                бренди, модельні агентства, кастинг-директори, рекламні агентства, виробництво
                компанії, медіа, виставкові зали, перукарі та візажисти, PR / Events можна
                зв’язатися, якщо обліковий запис не схвалено, інформація про користувача
                відсутня, а також можна надіслати нагадування про розміщення кастингу. Крім
                того, з користувачем можна зв’язатися через згадані канали, якщо інший
                користувач поставив лайк, сподобався зображення або підписався, або зі змінами
                та оновленнями на нашому сайті, рекламними пропозиціями, опитуваннями,
                порадами, новинами тощо.</p>
            <h2>IP-адреси та файли cookie</h2>
            <p>IP-адреса тимчасово зберігається для безпеки та запобігання спаму.</p>
            <p>Веб-сайт <a href="https://www.ibrandapp.com/" className="a" target="_blank">www.ibrandapp.com</a> і
                мобільний додаток iBrand: Models Castings Events
                використовує файли cookie для збереження налаштувань відвідувачів, запису
                інформації про сеанс, запису інформації про користувача про те, які сторінки
                користувачі відкривають або відвідують, запису минулої активності на сайті, щоб
                забезпечити кращу послуги, коли відвідувачі повертаються на наш сайт,
                налаштовувати вміст веб-сторінки на основі типу браузера відвідувача або іншої
                інформації, яку надсилає відвідувач.</p>
            <h2>Технічна охорона</h2>
            <p>Ми повідомимо орган із захисту даних про будь-які порушення даних і проблеми
                безпеки протягом 72 годин після виявлення. Ми також повідомимо електронною
                поштою всіх користувачів, які безпосередньо постраждали від цього порушення.</p>
            <h2>Як ви можете контролювати використання та відображення вашої інформації?</h2>
            <p>Усі дані, які ми збираємо, використовуються, щоб надати вам та іншим
                користувачам найкращий досвід роботи з мобільним додатком.</p>
            <p>Ви маєте право редагувати або видаляти певну інформацію про себе у своєму
                обліковому записі, але це може призвести до того, що наш сайт стане для вас
                менш корисним.</p>
            <p>Ви маєте право на видалення вашого облікового запису та інформації про вас з
                нашого додатку. Ви можете знайти це, увійшовши у свій обліковий запис,
                відвідавши «Налаштування» і натиснувши кнопку: «Видалити обліковий запис».</p>
            <p>Якщо ви хочете ввімкнути або відмовитися від електронних листів, які ми вам
                надсилаємо, це можна змінити в розділі «Редагувати мій профіль» вашого
                облікового запису. Певні важливі електронні листи все ще можуть надсилатися
                вам, наприклад, якщо ви забули свій пароль або ми оновлюємо наші Умови
                використання чи Політику конфіденційності.</p>
            <p>Ви можете подати запит на перегляд будь-якої особистої інформації, яку ми
                зберігаємо про вас. Якщо ви хочете побачити цю інформацію, будь ласка,
                зв’яжіться з нами за адресою: info@ibrandapp.com і мобільним додатком iBrand:
                Models Castings Events. Запити на інформацію триватимуть до 30 днів.</p>
            <h2>Зміни в цій політиці конфіденційності</h2>
            <p>Ми залишаємо за собою право вносити зміни в цю Політику конфіденційності в
                будь-який час. На цій сторінці буде показано сповіщення про внесені зміни. Якщо
                користувач заперечує проти оновленої Політики конфіденційності, він повинен
                подати запит на видалення свого облікового запису та видалення особистих
                даних.</p>
            <p>Ми сподіваємося, що ця Політика конфіденційності відповість на будь-які ваші
                запитання. Якщо у вас виникнуть додаткові запитання, зв’яжіться з нами
                безпосередньо за адресою: info@ibrandapp.com</p>
            <h2>Зв'яжіться з нами</h2>
            <p><br/></p>
            <p>Full name: IBrand Development OÜ</p>
            <p>Director: Klymenko Oleksandr</p>
            <p>Registration number: 16622229</p>
            <p>Adress: Kamanina tänav, 16a/1, Odessa, Ukraine, 65000</p>
            <p>Email: <a href="mailto:info@ibrandapp.com" target="_blank">info@ibrandapp.com</a></p>
            <p>Phone number: <a href="tel:+496170961709">+ 38 (099) 527 - 64 - 77</a></p>
        </div>
    );
}
const PolicyTextRu = () => {
    return (
        <div className="policy-content">
            <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>
            <p>Настоящая политика конфиденциальности применима к веб-сайту <a href="https://www.ibrandapp.com"
                                                                              className="a"
                                                                              target="_blank">www.ibrandapp.com</a>
                и мобильному приложению iBrand: Models Castings Events.</p>
            <h2>Как мы обрабатываем любые персональные данные, которые вы нам предоставляете</h2>
            <p>Когда вы присоединяетесь к веб-сайту <a href="https://www.ibrandapp.com/" className="a"
                                                       target="_blank">www.ibrandapp.com</a> и мобильному
                приложению iBrand: Models Castings Events, мы хотим, чтобы вы точно знали, что
                происходит с вашими данными, чтобы вы могли быть полностью уверены в том,
                что являетесь одним из наших ценных и любимых участников.</p>
            <p>Информация, которую мы запрашиваем у вас, предназначена только для одной
                цели: улучшить ваш опыт и помочь вам получить максимальную отдачу от участия
                в веб-сайте и мобильном приложении, а также для использования нашей
                платформы в деловых отношениях с вашими коллегами. Чтобы дать вам еще
                больше спокойствия, мы заботимся о ваших данных должным образом и никогда
                не передаем их третьим лицам.</p>
            <h2>Какие данные мы собираем?</h2>
            <p>Для моделей и талантов собираются следующие данные:</p>
            <ul>
                <li>Имя и/или название компании</li>
                <li>Адрес электронной почты</li>
                <li>Расположение</li>
                <li>Пол</li>
                <li>Ссылка на сайт или портфолио, если оно у вас есть</li>
                <li>Пароль, который зашифрован</li>
                <li>Дата рождения</li>
                <li>Дата, когда вы зарегистрировались, ваш последний вход в систему,
                    загруженные изображения, подписка на другого пользователя, избранное,
                    понравившееся или добавленное в избранное изображение или другого
                    пользователя
                </li>
                <li>Этническая принадлежность, национальность, цвет волос и глаз, рост,
                    размер обуви, размер груди, размер талии, размер бедер
                </li>
                <li>Языки</li>
                <li>Моделирование категорий и дисциплин, которые подходят вашему образу</li>
                <li>Дата, когда вы подаете заявку на кастинг или принимаете/отклоняете
                    бронирование
                </li>
                <li>Область свободного текста под названием «Обо мне», где вы можете
                    оставить любую важную профессиональную информацию о себе.
                </li>
                <li>Номер телефона</li>
                <li>Ссылки на социальные сети</li>
                <li>Загруженные изображения</li>
            </ul>
            <p>Для фотографов, брендов, модельных агентств, кастинг-директоров, рекламных
                агентств, продюсерских компаний, СМИ, шоу-румов, парикмахеров и визажистов,
                PR/Events эти данные собираются:</p>
            <ul>
                <li>Имя и/или название компании</li>
                <li>Адрес электронной почты</li>
                <li>Расположение</li>
                <li>Пол</li>
                <li>Ссылка на сайт или портфолио, если оно у вас есть</li>
                <li>Пароль, который зашифрован</li>
                <li>Дата рождения</li>
                <li>Подробная информация о ваших транзакциях на нашем сайте</li>
                <li>Дата, когда вы зарегистрировались, ваш последний вход в систему,
                    загруженные изображения, подписка на другого пользователя, избранное,
                    понравившееся или добавленное в избранное изображение или другого
                    пользователя
                </li>
                <li>Область свободного текста под названием «Обо мне», где вы можете
                    оставить любую важную профессиональную информацию о себе.
                </li>
                <li>Номер телефона</li>
                <li>Ссылки на социальные сети</li>
                <li>Загруженные изображения</li>
            </ul>
            <h2>Каким провайдерам мы передаем ваши данные?</h2>
            <p>Analytics — GTM с файлами cookie <a href="https://www.google.com/analytics/learn/privacy.html"
                                                   className="s2" target="_blank">Google Analytics</a> и данными об
                использовании</p>
            <p>Маркетинг - <a href="https://www.facebook.com/about/privacy/" className="a" target="_blank">Facebook
                Pixel. </a> Cookies и данные об использовании</p>
            <p><a href="https://www.twilio.com/legal/privacy" target="_blank">Twilio</a> - SMS, номер телефона, данные
                об использовании</p>
            <p>Доступ к сторонней учетной записи. Это компании, которые вы можете
                использовать на нашем веб-сайте, чтобы упростить определенные задачи.
                Например, если вы решите зарегистрироваться на нашем сайте через Facebook
                или если вы решите пригласить свои личные контакты присоединиться к нашему
                сайту через свою собственную базу данных электронной почты. </p>
            <p><a href="https://policies.google.com/privacy" className="a" target="_blank">Facebook</a> -
                Обо мне, день рождения, город, адрес электронной почты, веб-сайт</p>
            <p><a href="https://policies.google.com/privacy" className="a" target="_blank">Google</a> - Контакты</p>
            <p>Защита от спама - <a href="https://policies.google.com/privacy" className="s2"
                                    target="_blank">Google reCAPTCHA</a></p>
            <p>Различные типы данных, как указано в политике конфиденциальности сервиса</p>
            <p><br/></p>
            <h2>Каким третьим сторонам мы передаем ваши
                данные?</h2>
            <p>Мы не будем передавать ваши данные третьим лицам, если у нас нет вашего
                разрешения или если это требуется по закону.</p>
            <h2>Как долго мы храним ваши данные?</h2>
            <p>Ваши данные будут храниться до тех пор, пока вы являетесь пользователем
                нашего сайта и не запрашивали удаление вашей учетной записи и данных.</p>
            <h2>Где он хранится?</h2>
            <p>Все данные хранятся на наших защищенных европейских серверах.</p>
            <h2>Есть ли у нас законные причины, по которым нам
                нужны эти данные?</h2>
            <p>Законными причинами, по которым нам нужны эти данные, являются законные
                интересы, преследуемые компанией, установленные GDPR (статья 6).</p>
            <h2>Что мы делаем с этой информацией?</h2>
            <p>Анализируйте действия пользователей, чтобы улучшить наш сервис.</p>
            <p>Обеспечьте высокое качество и, в конечном счете, полезный пользовательский
                опыт на нашем сайте и при общении по электронной почте.</p>
            <p>Мы можем уведомлять вас по электронной почте, телефону с помощью SMS или
                Whatsapp, Facebook Messenger с важной информацией, относящейся к вашей
                учетной записи. По этим каналам модели могут быть уведомлены, если они были
                приглашены на кастинг или если получено новое сообщение от участника. Кроме
                того, если другому пользователю понравились, понравились изображения или он
                подписался, или с изменениями и обновлениями на нашем сайте, рекламными
                предложениями, опросами, советами, новостями и т. д. Другие пользователи,
                такие как фотографы, бренды, модельные агентства, директора по кастингу,
                рекламные агентства, производство компании, СМИ, салоны, парикмахеры и
                визажисты, PR / Events можно связаться, если учетная запись не одобрена,
                информация о пользователе отсутствует, а также может быть отправлено
                напоминание о проведении кастинга. Кроме того, с пользователем можно
                связаться по указанным каналам, если другому пользователю понравились,
                понравились изображения или он подписался, или с изменениями и
                обновлениями на нашем сайте, рекламными предложениями, опросами,
                советами, новостями и т. д.</p>
            <h2>IP-адреса и файлы cookie</h2>
            <p>IP-адрес временно сохраняется в целях безопасности и предотвращения спама.</p>
            <p>Веб-сайт <a href="https://www.ibrandapp.com/" className="a" target="_blank">www.ibrandapp.com</a> и
                мобильное приложение iBrand: Models Castings
                Events использует файлы cookie для хранения предпочтений посетителей, записи
                информации о сеансе, записи информации о пользователях о том, какие
                страницы посещают или посещают пользователи, записи прошлой активности на
                сайте, чтобы обеспечить лучшее сервис, когда посетители возвращаются на наш
                сайт, настроить содержимое веб-страницы в зависимости от типа браузера
                посетителя или другой информации, которую посетитель отправляет.</p>
            <h2>Техническая безопасность</h2>
            <p>Мы сообщим о любых утечках данных и проблемах безопасности в орган по
                защите данных в течение 72 часов с момента обнаружения. Мы также уведомим
                по электронной почте всех пользователей, которые непосредственно затронуты
                этим нарушением</p>
            <h2>Как вы можете контролировать использование и
                отображение вашей информации?</h2>
            <p>Все данные, которые мы собираем, используются, чтобы предоставить вам и
                другим пользователям наилучшие возможности мобильного приложения.</p>
            <p>Вы имеете право редактировать или стирать определенную информацию о себе в
                своей учетной записи, но это может привести к тому, что наш сайт станет для вас
                менее полезным.</p>
            <p>Вы имеете право на удаление вашей учетной записи и информации о вас с
                нашего мобильного приложения. Вы можете найти это, войдя в свою учетную
                запись и посетив «Настройки» и нажав кнопку: «Удалить учетную запись».</p>
            <p>Если вы хотите подписаться на электронные письма, которые мы отправляем вам,
                или отказаться от них, это можно изменить в разделе «Редактировать мой
                профиль» вашей учетной записи. Некоторые важные электронные письма могут
                по-прежнему отправляться вам, например, если вы забыли свой пароль или если
                мы обновляем наши Условия и положения или Политику конфиденциальности.</p>
            <p>Вы можете запросить подробную информацию о любой личной информации,
                которую мы храним о вас. Если вы хотите увидеть эту информацию, пожалуйста,
                свяжитесь с нами по адресу: info@ibrandapp.com и через мобильное приложение
                iBrand: Models Castings Events. Запросы информации занимают до 30 дней.</p>
            <h2>Изменения в настоящей политике конфиденциальности</h2>
            <p>Мы оставляем за собой право вносить изменения в настоящую Политику
                конфиденциальности в любое время. На этой странице появится уведомление о
                том, что были внесены изменения. Если пользователь возражает против
                обновленной Политики конфиденциальности, он должен запросить удаление
                своей учетной записи и удаление личных данных.</p>
            <p>Мы надеемся, что эта Политика конфиденциальности ответит на любые ваши
                вопросы. Если у вас есть какие-либо дополнительные вопросы, не стесняйтесь
                обращаться к нам напрямую по электронной почте: info@ibrandapp.com</p>
            <h2>Связаться с нами</h2>
            <p><br/></p>
            <p>Full name: IBrand Development OÜ</p>
            <p>Director: Klymenko Oleksandr</p>
            <p>Registration number: 16622229</p>
            <p>Adress: Kamanina tänav, 16a/1, Odessa, Ukraine, 65000</p>
            <p>Email: <a href="mailto:info@ibrandapp.com" target="_blank">info@ibrandapp.com</a></p>
            <p>Phone number: <a href="tel:+496170961709">+ 38 (099) 527 - 64 - 77</a></p>
        </div>
    );
}

const PolicyTextEn = () => {
    return (
        <div className="policy-content">
            <h1>PRIVACY POLICY</h1>
            <p>This privacy policy is applicable for website <a href="https://www.ibrandapp.com" className="a"
                                                                target="_blank">www.ibrandapp.com</a> and mobile
                application iBrand: Models Castings Events.</p>
            <h2>How we handle any personal data you give us</h2>
            <p>When you join website <a href="https://www.ibrandapp.com/" className="a"
                                        target="_blank">www.ibrandapp.com</a> and mobile application iBrand: Models
                Castings Events we want you to know exactly what happens with your data, so you can have complete
                confidence in being one of our valued and much loved members.</p>
            <p>The information that we request from you is for one purpose only, to improve your experience and help you
                get the most from being a member of a website and mobile application and to use our platform in a
                business to business way with your peers. To give you even more peace of mind, we look after your data
                in the right way and never share it with any 3rd parties.</p>
            <h2>What data do we collect?</h2>
            <p>For models and talents this data is collected :</p>
            <ul>
                <li>Name and/or company name</li>
                <li>Email address</li>
                <li>Location</li>
                <li>Gender</li>
                <li>Website or portfolio link, if you have one</li>
                <li>Password which is encrypted</li>
                <li>Date of birth</li>
                <li>Date when you signed up, your last login, uploaded images, followed another user, loved, liked or
                    favorited an image or another user
                </li>
                <li>Ethnicity, nationality, hair and eye colour, height, shoe size, bust size, waist size, hips size
                </li>
                <li>Languages</li>
                <li>Modeling categories and disciplines that suit your look</li>
                <li>Date when you apply to castings, or accepted/declined a booking</li>
                <li>Free text area called &quot;About me&quot; where you can leave any relevant professional information
                    about yourself
                </li>
                <li>Phone number</li>
                <li>Social media links</li>
                <li>Uploaded imagesFor photographers, brands, model agencies, casting directors, advertising agencies,
                    production companies, media, showrooms, hair and makeup artists, PR / Events this data is collected:
                </li>
                <li>Name and/or company name</li>
                <li>Email address</li>
                <li>Location</li>
                <li>Gender</li>
                <li>Website or portfolio link, if you have one</li>
                <li>Password which is encrypted</li>
                <li>Date of birth</li>
                <li>Date when you signed up, your last login, uploaded images, followed another user, loved, liked or
                    favorited an image or another user
                </li>
                <li>Visual media industry sector</li>
                <li>Free text area called &quot;About me&quot; where you can leave any relevant professional information
                    about yourself
                </li>
                <li>Phone number</li>
                <li>Social media links</li>
                <li>Uploaded images</li>
            </ul>
            <h2>Which providers do we share your data with?</h2>
            <p>Analytics - GTM with <a href="https://www.google.com/analytics/learn/privacy.html"
                                       className="s2" target="_blank">Google Analytics</a> Cookies and usage data</p>
            <p>Marketing - <a href="https://www.facebook.com/about/privacy/" className="a" target="_blank">Facebook pixel</a> Cookies and usage data</p>
            <p>SMS - <a href="https://www.twilio.com/legal/privacy" target="_blank">Twilio</a>. Phone number, usage data</p>
            <p>Access to third-party account. These are companies you can decide to use on our<span
                className="p"> </span>website to make certain tasks simpler. For example if you decide to sign up to our
                site<span className="p"> </span>with Facebook, or if you decide to invite your personal contacts to join
                our site via your<span className="p"> </span>own email database.</p>
            <p><a href="https://policies.google.com/privacy" className="a" target="_blank">Facebook</a> -
                About me, birthday, city, email address, website </p>
            <p><a href="https://policies.google.com/privacy" className="a" target="_blank">Google</a> - Contacts</p>
            <p>SPAM protection - <a href="https://policies.google.com/privacy" className="s2"
                                   target="_blank">Google reCAPTCHA</a></p>
            <p>Various types of data as specified in the privacy policy of the service</p>
            <p><br/></p>
            <h2>Which 3rd parties do we share your data with?</h2>
            <p>We do will not share your data with any third parties, unless we have your permission or are required by
                law to do so.</p>
            <h2>How long do we keep your data?</h2>
            <p>Your data will be kept as long as you are a user of our site and have not requested that your account and
                data to be erased.</p>
            <h2>Where is it stored?</h2>
            <p>All data is stored on our secure European based servers.</p>
            <h2>Do we have lawful reasons why we need this data?</h2>
            <p>The lawful reasons why we need this data are legitimate interests pursued by the company, as established
                by the GDPR (Article 6).</p>
            <h2>What do we do with this information?</h2>
            <p>Analyse user activity to improve our service.</p>
            <p>Provide a high quality and ultimately useful user experience throughout our site and email
                communication.</p>
            <p>We may notify you by email, phone by SMS or Whatsapp, Facebook Messenger with important information of
                relevance to your account. Models can be notified by these channels if they have been invited to a
                casting or if a new message from a member is received. In addition if another user has liked, loved
                images or followed, or with changes and updates to our site, promotional offers, surveys, tips, news
                etc. Other users such as photographers, brands, model agencies, casting directors, advertising agencies,
                production companies, media, showrooms, hair and make up artists, PR / Events can be contacted if the
                account is not approved, information about the user is missing as well as reminder to post a casting can
                be sent. In addition user can be contacted by mentioned channels if another user has liked, loved images
                or followed, or with changes and updates to our site, promotional offers, surveys, tips, news etc.</p>
            <h2>IP addresses and Cookies</h2>
            <p>IP address is stored temporarily for security and spam prevention.</p>
            <p><a href="http://www.ibrandapp.com/" className="a" target="_blank">Website </a>www.ibrandapp.com and
                mobile
                application
                iBrand: Models Castings Events uses cookies to store visitors’ preferences, record session
                information, record
                user-specific information on what pages users access or visit, record past activity at a site in order
                to provide
                better service when visitors return to our site, customise Web page content based on
                visitors&#39; browser type or
                other information that the visitor sends.</p>
            <h2>Technical security</h2>
            <p>We will
                report any
                data breaches and security issues to the data protection authority within 72 hours of discovery. We
                will also notify
                by email any users who are directly affected by this breach.</p>
            <h2>How you can control the
                use and
                display of your information?</h2>
            <p>All data
                that we
                gather is used to give you and other users the best possible experience on mobile application.</p>
            <p>You have
                the right to
                edit or erase certain information about you on your account, but this may result in our site being
                less useful for
                you.</p>
            <p>You
                have the right
                for your account and information about you to be erased from our app. You can find this by logging
                into your account
                and visiting the “Settings” and by clicking the button: “Delete account”.</p>
            <p>If you
                want to opt in
                or out of the emails we send you, these can be changed in the Edit my profile section of your account.
                Certain
                important emails may still be sent to you, for example if you&#39;ve forgotten your password, or if we
                update our
                Terms and Conditions or Privacy Policy.</p>
            <p><a
                href="mailto:info@ibrandapp.com" className="a" target="_blank">You can request to see details of any
                personal
                information which we hold about you. If you would like to see this information, please contact us
                at: </a>info@ibrandapp.com
                and mobile application iBrand: Models Castings Events. Requests of information will take up to 30
                days.</p>
            <h2>Changes to this privacy
                policy</h2>
            <p>We
                reserve the right
                to make changes to this Privacy Policy at any time. This page will show a notice that changes have
                been made. If a
                user objects to the updated Privacy Policy, they should request for their account to be erased and
                personal data
                removed.</p>
            <p><a
                href="mailto:info@ibrandapp.com" className="a" target="_blank">We hope that this Privacy Policy answers
                any
                questions that you may have. If you have any further questions, feel free to contact us directly here
                by email
                at: </a><a href="mailto:info@ibrandapp.com" target="_blank">info@ibrandapp.com.</a></p>
            <p><br/></p>
            <h2>Contact us</h2>
            <p><br/></p>
            <p>Full name: IBrand Development OÜ</p>
            <p>Director: Klymenko Oleksandr</p>
            <p>Registration number: 16622229</p>
            <p>Adress: Kamanina tänav, 16a/1, Odessa, Ukraine, 65000</p>
            <p>Email: <a href="mailto:info@ibrandapp.com" target="_blank">info@ibrandapp.com</a></p>
            <p>Phone number: <a href="tel:+496170961709">+ 38 (099) 527 - 64 - 77</a></p>
        </div>
    );
}
