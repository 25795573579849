import i18n from "i18next";
import {Fragment} from "react";
import {OfferHeader} from "../offer/OfferHeader";
import {Footer} from "../common/Footer";
import './Terms.scss';

export const TermsPage = () => {

    const getTermsContent = () => {
        switch (i18n.language) {
            case 'ua':
                return <TermsUA/>;
            case 'ru':
                return <TermsRU/>;
            case 'en':
            default:
                return <TermsEN/>;
        }
    }

    return (
        <Fragment>
            <OfferHeader/>
            {getTermsContent()}
            <Footer/>
        </Fragment>
    );
}

const TermsUA = () => {
    return (
        <div className="terms-content">
            <h2>Загальні умови користування вебсайтом www.ibrandapp.com та мобільним додатком iBrand: Models Castings
                Events</h2>
            <p>
                Увійшовши на веб-сайт www.ibrandapp.com і в мобільний додаток iBrand: Models
                Castings Events, користувачі приймають такі Загальні положення та умови (далі
                &quot;GTC&quot;) щодо використання веб-сайту www.ibrandapp.com і мобільного додатку
                iBrand: Models Castings Events. Користувач укладає цю угоду про використання
                веб-сайту www.ibrandapp.com та мобільного додатку iBrand: Models Castings
                Events Services з Товариством з IBrand Development OÜ, Kamanina tänav, 16a/1, Odessa, Ukraine, 65000.
                Додаткова контактна інформація, дані комерційного реєстру, а також ім’я уповноваженого
                представника веб-сайту www.ibrandapp.com і мобільного додатку iBrand: Models
                Castings Events доступні через <b>&quot;Політику конфіденційності&quot;.</b>
            </p>
            <h2>A. ВИЗНАЧЕННЯ</h2>
            <p><b>&quot;Замовники&quot;:</b> Pфотографи, бренди, модельні агентства, кастинг-директори,
                рекламні агентства, продакшн-компанії, засоби масової інформації, виставкові
                зали, перукарі та візажисти, PR/події, інші професіонали галузі та глядачі, які
                відвідують або використовують веб-сайт www.ibrandapp.com або мобільний
                додаток iBrand : Моделі Кастинги Події;</p>
            <p><b>&quot;Моделі&quot;:</b> Таланти, які відвідують або використовують веб-сайт
                www.ibrandapp.com і мобільний додаток iBrand: Моделі Кастинги Події;</p>
            <p><b>&quot;GTCs&quot;:</b> Загальні положення та умови використання веб-сайту www.ibrandapp.com
                і мобільного додатку iBrand: Моделі Кастинги Події</p>
            <p><b>&quot;Веб-сайт&quot;:</b> www.ibrandapp.com</p>
            <p><b>&quot;Мобільний додаток&quot;:</b> iBrand: Моделі Кастинги Події</p>
            <h2>B. ВИКОРИСТАННЯ веб-сайту www.ibrandapp.com і мобільного додатку iBrand: Моделі Кастинги Події</h2>
            <p><b>1. Предмет</b></p>
            <p>
                1.1 веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models Castings
                Events пропонує моделям платформу для представлення своїх профілів і
                фотокниг, а також для організації ділових контактів, зокрема з агентами/агенціями
                та фотографами.
            </p>
            <p>
                1.2 Модель визнає та погоджується, що технічно неможливо досягти 100%
                доступності веб-сайту www.ibrandapp.com і мобільного додатку iBrand: Models
                Castings Events. веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models
                Castings Events, незважаючи на це, будуть докладати зусиль, щоб веб-сайт
                www.ibrandapp.com і мобільний додаток iBrand: Models Castings Events були
                доступними без перерв. Події, пов’язані з техобслуговуванням, безпекою чи
                вимогами до потужності, та/або події поза веб-сайтом www.ibrandapp.com і
                мобільним додатком iBrand: Models Castings Контроль подій (наприклад, збої в
                громадських мережах зв’язку, збої в електроживленні тощо), можуть призвести до
                короткочасних збоїв або тимчасові перерви в роботі послуг, що надаються на сайті
                www.ibrandapp.com і в мобільному додатку iBrand: Models Castings Events
            </p>
            <p>
                1.3 Веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models Castings
                Events надають Моделям платформу для встановлення контактів з іншими
                особами чи організаціями, зокрема модельними агентствами. Веб-сайт
                www.ibrandapp.com і мобільний додаток iBrand: Models Castings Events не беруть
                участі в будь-якому. Якщо користувачі укладають угоди один з одним через вебсайт www.ibrandapp.com і
                мобільний додаток iBrand: Models Castings Events, вебсайт www.ibrandapp.com і мобільний додаток iBrand:
                Models Castings Events не є
                стороною цих угод. Лише користувачі несуть відповідальність за виконання та/або
                виконання угод, які вони укладають один з одним. веб-сайт www.ibrandapp.com і
                мобільний додаток iBrand: Models Castings Events не несуть відповідальності, якщо
                користувачі не можуть зв’язатися один з одним через веб-сайт www.ibrandapp.com
                і мобільний додаток iBrand: Models Castings Events щодо таких угод. Крім того,
                веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models Castings Events не
                несуть відповідальності за порушення угод, укладених між користувачами.
            </p>
            <p><b>2. Реєстрація в мобільному додатку iBrand: Models Castings Events</b>
            </p>
            <p>2.1 Моделі повинні зареєструватися, перш ніж користуватися послугами веб-сайту
                www.ibrandapp.com або мобільного додатку iBrand: Models Castings Events.</p>
            <p>
                2.2 Модель повинна надавати лише точні та повні дані для реєстрації.
                Реєстраційні дані включають псевдонім (в Інтернеті), який Модель вибирає для
                публічного показу в профілі Моделі. Модель повинна повідомляти про будь-які
                зміни в реєстраційних даних на веб-сайті www.ibrandapp.com і в мобільний додаток
                iBrand: Models Castings Events без зайвої затримки.
            </p>
            <p>
                2.3 Реєстрація на платформі неповнолітнім особам заборонена, якщо вони не
                мають попереднього та прямого дозволу своїх батьків, опікунів або законних
                представників, які будуть нести відповідальність за дії, вчинені неповнолітніми, які
                перебувають під їх опікою. , відповідно до чинних норм. У будь-якому випадку буде
                вважатися, що реєстрація на платформі www.ibrandapp.com або мобільному
                додатку iBrand: Models Castings Events неповнолітньою особою була здійснена з
                попереднього та прямого дозволу її/її батьків, опікуни або законні представники.
                Щоб отримати попередню інформацію про встановлений законом вік у кожній
                країні, перегляньте <a href="https://en.wikipedia.org/wiki/Age_of_majority">додану сторінку у
                Вікіпедії</a>
            </p>
            <p>
                2.4 Модель під час реєстрації вибирає пароль, необхідний для доступу до свого
                облікового запису на веб-сайті www.ibrandapp.com та мобільного додатку iBrand:
                Models Castings Events. Модель зобов'язана зберігати цей пароль в секреті. Вебсайт www.ibrandapp.com і
                мобільний додаток iBrand: Models Castings Events не
                повинні розголошувати пароль будь-якій третій стороні, а співробітники веб-сайту
                www.ibrandapp.com і мобільного додатку iBrand: Models Castings Events не повинні
                запитувати пароль моделі. Модель повинна негайно повідомити веб-сайт
                www.ibrandapp.com і мобільний додаток iBrand: Models Castings Events про будьяке несанкціоноване
                використання його/її пароля або облікового запису.
            </p>
            <p>
                2.5 Завершивши процес реєстрації, Модель приймає ці Загальні умови
                використання послуг веб-сайту www.ibrandapp.com і мобільного додатку iBrand:
                Models Castings Events. веб-сайт www.ibrandapp.com і мобільний додаток iBrand:
                Models Castings Events приймає цю пропозицію, активувавши послуги на веб-сайті
                www.ibrandapp.com і мобільний додаток iBrand: Models Castings Events.
            </p>
            <p>
                2.6 Погоджено, що коли Модель заповнить інформацію про профіль, таку як стать,
                етнічна приналежність, вік тощо, вона буде показана публічно.
            </p>
            <p><b>3. Обов'язки моделі</b></p>
            <p>
                3.1 Модель зобов’язана надавати лише правдиві твердження, які не вводять в
                оману, у своєму профілі та під час спілкування з іншими користувачами.
            </p>
            <p>
                3.2 Модель повинна публікувати на веб-сайті www.ibrandapp.com і в мобільному
                додатку iBrand: Models Castings Events лише фотографії, відео, вміст та інші
                матеріали, які відповідають інструкціям щодо фотографій, опублікованих вебсайтом www.ibrandapp.com і
                мобільний додаток iBrand: Models Castings Events.
                Модель гарантує та заявляє, що публічний показ такого Контенту не заборонений.
                Модель погоджується, що Довірений фотограф може безпосередньо
                завантажувати фотографії та відео моделі, які вони знімають.
            </p>
            <p>
                3.3 Контент, опублікований Моделью, має відповідати всьому чинному
                законодавству та поважати всі права третіх сторін. Зокрема, Модель не має права:
                (i) публікувати будь-який порнографічний, образливий або наклепницький Вміст,
                або будь-який Вміст, який порушує будь-яке чинне законодавство щодо захисту
                неповнолітніх; (ii) рекламувати чи пропагувати, пропонувати чи розповсюджувати
                будь-які продукти чи послуги, які не відповідають чинному законодавству щодо
                захисту неповнолітніх; (iii) використовувати без дозволу будь-який Вміст,
                захищений законом (наприклад, авторським правом, торговою маркою, патентом,
                патентом на корисність або патентом на дизайн).
            </p>
            <p>
                3.4 Модель не має права поширювати або публічно оприлюднювати вміст вебсайту www.ibrandapp.com і
                мобільного додатку iBrand: Models Castings Events, а
                також виконувати будь-які дії, які можуть погіршити працездатність веб-сайту
                www.ibrandapp.com і мобільного додатку iBrand: Models Castings. Інфраструктура
                подій, зокрема дії, які можуть перевантажити цю інфраструктуру.
            </p>
            <p>
                3.5 Модель погоджується, що веб-сайт www.ibrandapp.com і мобільний додаток
                iBrand: Models Castings Events можуть використовувати його/її ім’я та посилання на
                клієнта, наприклад, у публікаціях про історії успіху, а також можуть публічно
                розкривати та рекламувати той факт, що Модель використовує веб-сайт
                www.ibrandapp.com і мобільний додаток iBrand: Models Castings.
            </p>
            <p><b>4. Зміни в Послугах на веб-сайті www.ibrandapp.com і мобільному додатку iBrand: Моделі Кастинги
                Події</b></p>
            <p>
                Веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models Castings Events
                залишає за собою право змінювати послуги, пропоновані на веб-сайті
                www.ibrandapp.com і мобільному додатку iBrand: Models Castings Events, та/або
                пропонувати послуги, відмінні від тих, що пропонуються на той час реєстрації
                Моделі в будь-який час, якщо це не є необґрунтованим для Моделі.
            </p>
            <p><b>5. Припинення</b></p>
            <p>
                5.1 Модель може припинити дію свого облікового запису на веб-сайті мобільного
                додатку iBrand: Models Castings Events у будь-який час без причини. Модель може
                видалити обліковий запис, відвідавши налаштування в додатку та натиснувши
                кнопку &quot;Видалити обліковий запис&quot;.
            </p>
            <p><b>6. Відповідальність за вміст, дані чи іншу інформацію</b></p>
            <p>
                6.1 Веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models Castings
                Events не надає жодних гарантій або заяв щодо будь-яких даних та/або
                інформації, наданих або доступних будь-яким користувачем веб-сайту
                www.ibrandapp.com і мобільного додатку iBrand: Models Castings Events. Зокрема,
                веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models Castings Events не
                гарантують і не стверджують, що зазначені дані та/або інформація є правдивими
                чи точними, або що вони відповідають чи служать будь-якій конкретній меті.
            </p>
            <p>
                6.2 Модель повинна повідомляти про будь-які дії будь-якого іншого користувача,
                які порушують відповідні закони та/або будь-які з цих GTC, використовуючи
                контактну форму, доступну на веб-сайті www.ibrandapp.com, і мобільний додаток
                iBrand: Models Castings Events.
            </p>
            <p><b>7. Обслуговування клієнтів/Підтримка</b></p>
            <p>
                Запити щодо угоди з веб-сайтом www.ibrandapp.com і мобільним додатком iBrand:
                Models Castings Events або щодо веб-сайту www.ibrandapp.com і мобільного
                додатка iBrand: Models Castings Events послуг можна надсилати на веб-сайт
                www.ibrandapp.com і мобільний додаток iBrand : Models Castings Events,
                використовуючи контактну форму, доступну на веб-сайті www.ibrandapp.com, і
                мобільний додаток iBrand: Models Castings Events або надіславши електронний
                лист на info@ibrandapp.com
            </p>
            <p><b>8. Відповідальність веб-сайту www.ibrandapp.com і мобільного додатку
                iBrand: Моделі Кастинги Події</b></p>
            <p>
                Веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models Castings Events
                (включно з його допоміжними агентами) несуть відповідальність лише за збитки,
                заподіяні Моделі внаслідок її облікового запису на веб-сайті www.ibrandapp.com і
                мобільного додатку iBrand: Models Castings Events, якщо веб-сайт
                www.ibrandapp.com і мобільний додаток iBrand: Models Castings Events суттєво
                порушує ці GTC і не усунув таке порушення (якщо його можна усунути),
                незважаючи на обґрунтований період усунення, встановлений Моделлю.
            </p>
            <p><b>9. Відшкодування</b></p>
            <p>
                9.1 Модель зобов’язана звільнити веб-сайт www.ibrandapp.com і мобільний
                додаток iBrand: Models Castings Events від будь-яких дій, включаючи претензії
                щодо збитків, висунутих іншими користувачами або третіми особами проти вебсайту www.ibrandapp.com і
                мобільного додатка iBrand: Models Castings Events
                моделей, які є результатом порушення їхніх прав Контентом, опублікованим
                Моделью на веб-сайті www.ibrandapp.com і в мобільному додатку iBrand: Models
                Castings Events. Модель бере на себе всі обґрунтовані витрати веб-сайту
                www.ibrandapp.com і мобільного додатку iBrand: Models Castings Events, пов’язані з
                порушенням прав третіх сторін, включаючи всі обґрунтовані витрати на судовий
                захист. Усі інші права веб-сайту www.ibrandapp.com і мобільного додатку iBrand:
                Models Castings Events, включаючи право вимагати відшкодування збитків,
                залишаються без змін.
            </p>
            <p>
                9.2 У випадку, якщо Вміст, опублікований Моделлю, порушує будь-які права будьякої третьої сторони,
                Модель за власний рахунок і на веб-сайті www.ibrandapp.com
                і мобільному додатку iBrand: Models Castings Events на розсуд або отримує право
                використовувати зазначений Вміст або робити зазначений Вміст вільним від будьяких порушень.
            </p>
            <p><b>10. Захист даних</b></p>
            <p>
                Веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models Castings Events
                визнають, що будь-які особисті дані, надані Моделлю веб-сайту
                www.ibrandapp.com і мобільному додатку iBrand: Models Castings Events, є
                надзвичайно важливими для Моделі та веб-сайту www.ibrandapp. Тому мобільний
                додаток iBrand: Models Castings Events мають бути особливо чутливими до
                обробки таких даних. Веб-сайт www.ibrandapp.com і мобільний додаток iBrand:
                Models Castings Events повинні відповідати всім чинним законодавчим положенням
                щодо захисту даних. Зокрема, веб-сайт www.ibrandapp.com і мобільний додаток
                iBrand: Models Castings Events не повинні надавати або іншим чином
                розголошувати будь-які особисті дані Моделі будь-якій третій стороні без дозволу.
                Подробиці на веб-сайті www.ibrandapp.com і в мобільному додатку iBrand: Models
                Castings Events щодо обробки даних Користувача викладені в Політиці захисту
                даних веб-сайту www.ibrandapp.com і мобільного додатку iBrand: Models Castings
                Events.
            </p>
            <p><b>11. Репутація та відгуки</b></p>
            <p>
                Моделі погоджуються та визнають передачу авторських прав на всі відгуки та
                репутацію, які вони можуть публікувати на Веб-сайті. Цей відгук належить
                виключно веб-сайту www.ibrandapp.com і мобільному додатку iBrand: Models
                Castings Events. Моделі визнають, що вони не можуть використовувати цей відгук
                без попереднього письмового дозволу веб-сайту www.ibrandapp.com і мобільного
                додатку iBrand: Models Castings Events.
            </p>
            <p><b>12. Податки</b></p>
            <p>
                Моделі самі несуть відповідальність за сплату податків. Законний ПДВ, відповідно
                до Закону України, буде додано до комісії, що виставляється Моделям, якщо це
                застосовно.
            </p>
            <p><b>13. Мова</b></p>
            <p>
                Моделі, які приймають Загальні умови використання веб-сайту www.ibrandapp.com
                і мобільного додатку iBrand: Models Castings Events, заявляють, що вони повністю
                та досконало розуміють зміст англійської версії цих Загальних умов.
            </p>
            <p><b>14. Різне</b></p>
            <p>14.1 Будь-які поправки до цих GTC повинні бути зроблені в письмовій формі, щоб
                бути дійсними.</p>
            <p>
                14.2 Веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models Castings
                Events залишає за собою право в будь-який час вносити зміни до цих GTC без
                пояснення причин, за винятком випадків, коли ці зміни є необґрунтованими для
                моделі. Веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models Castings
                Events повинні повідомляти належним чином про будь-які зміни цих GTC щодо
                Моделі. Якщо протягом двох (2) тижнів після отримання зазначеного повідомлення
                Модель не заперечує проти застосування переглянутих Загальних загальних умов,
                змінені Загальні умови вважаються прийнятими Моделью. веб-сайт
                www.ibrandapp.com і мобільний додаток iBrand: Models Castings Events
                інформують Модель про право Моделі на заперечення та актуальність терміну
                подання заперечень у зазначеному повідомленні.
            </p>
            <p>
                14.3 Якщо інше не зазначено в цих GTC, Модель може надсилати всі сповіщення
                на веб-сайт www.ibrandapp.com і мобільний додаток iBrand: Models Castings
                Events, використовуючи контактну форму на веб-сайті www.ibrandapp.com і
                мобільний додаток iBrand: Models Castings Events, або надіславши електронний
                лист на адресу info@ibrandapp.com.
            </p>
            <p>
                14.4 Якщо будь-яке положення цих GTC з будь-якої причини є недійсним та/або
                таким, що не підлягає виконанню, решта положень залишаються дійсними та
                підлягають виконанню в повному обсязі, дозволеному законом. Сторони
                погоджуються замінити недійсне та/або невиконанне положення дійсним та/або
                виконуваним положенням, яке максимально наближається до наміру та
                економічної мети недійсного та/або невиконуваного положення. Це також
                стосується нормативних прогалин у цих GTC.
            </p>
        </div>
    );
}
const TermsEN = () => {
    return (
        <div className="terms-content">
            <h2>General terms and conditions for the use of website www.ibrandapp.com and mobile application iBrand:
                Models Castings Events </h2>
            <p>
                By logging on to website www.ibrandapp.com and mobile application iBrand: Models
                Castings Events users accept the following General Terms and Conditions (hereinafter
                the &quot;GTCs&quot;) for using website www.ibrandapp.com and mobile application iBrand:
                Models Castings Events. The user enters into this agreement on the use of website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events services
                with IBrand Development OÜ, Kamanina tänav, 16a/1, Odessa, Ukraine, 65000. Additional contact
                information, commercial registry
                data, as well as the name of the authorized representative of website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events are
                accessible via <b>&quot;Privacy Policy&quot;.</b>
            </p>
            <h2>A. DEFINITIONS</h2>
            <p><b>&quot;Customers&quot;:</b> Photographers, brands, model agencies, casting directors, advertising
                agencies, production companies, media, showrooms, hair and makeup artists, PR / Events other industry
                professionals and spectators visiting or using website www.ibrandapp.com or mobile application iBrand:
                Models Castings Events;</p>
            <p><b>&quot;Models&quot;:</b> Talents visiting or using website www.ibrandapp.com and mobile application
                iBrand: Models Castings Events;</p>
            <p><b>&quot;GTCs&quot;:</b> General Terms and Conditions for the use of website www.ibrandapp.com and mobile
                application iBrand: Models Castings Events</p>
            <p><b>&quot;Website&quot;:</b> www.ibrandapp.com</p>
            <p><b>&quot;Mobile application&quot;:</b> iBrand: Models Castings Events</p>
            <h2>B. USE OF website www.ibrandapp.com and mobile application iBrand: Models Castings Events</h2>
            <p><b>1. Subject Matter</b></p>
            <p>
                1.1 website www.ibrandapp.com and mobile application iBrand: Models Castings
                Events offers Models a platform for presenting their profiles and photo books and for
                arranging business contacts, particularly with agents/agencies and photographers.
            </p>
            <p>
                1.2 The Model acknowledges and agrees that it is technically impossible to achieve
                100% availability of website www.ibrandapp.com and mobile application iBrand: Models
                Castings Events. website www.ibrandapp.com and mobile application iBrand: Models
                Castings Events shall nonetheless endeavor to keep website www.ibrandapp.com and
                mobile application iBrand: Models Castings Events available without interruption.
                Events related to maintenance, security or capacity requirements, and/or events beyond
                website www.ibrandapp.com and mobile application iBrand: Models Castings Events
                control (e.g. disruptions in public communication networks, power failures etc.), may
                result in brief malfunctions or temporary interruptions of the services provided on
                website www.ibrandapp.com and mobile application iBrand: Models Castings Events.
            </p>
            <p>
                1.3 Website www.ibrandapp.com and mobile application iBrand: Models Castings
                Events provides Models with a platform on which to establish contact with other persons
                or entities, in particular model agencies. website www.ibrandapp.com and mobile
                application iBrand: Models Castings Events does not take part in any communication
                between Models and/or any other users of its services. If users enter into agreements
                with one another via website www.ibrandapp.com and mobile application iBrand:
                Models Castings Events, website www.ibrandapp.com and mobile application iBrand:
                Models Castings Events shall not be a contracting party to these agreements. The
                users alone are responsible for the execution and/or fulfillment of agreements in which
                they enter with one another. website www.ibrandapp.com and mobile application
                iBrand: Models Castings Events shall not be held liable if users are unable to contact
                one another over website www.ibrandapp.com and mobile application iBrand: Models
                Castings Events regarding such agreements. Furthermore, website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events shall not
                be liable for breaches of agreements entered into between users.
            </p>
            <p><b>2. Registration on website www.ibrandapp.com and mobile application iBrand: Models Castings Events</b>
            </p>
            <p>2.1 Models must register prior to using the services of website www.ibrandapp.com or mobile application
                iBrand: Models Castings Events.</p>
            <p>
                2.2 The Model shall only provide accurate and complete data for registration. The
                registration data include an alias (online) name to be chosen by the Model for public
                display with the Model’s profile, whereas the Model’s real name will not be made
                publicly available. The Model shall report any changes in the registration data to website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events without
                undue delay.
            </p>
            <p>
                2.3 The sign-up to the platform by persons of minor age is not allowed, unless they
                have the prior and express authorization of their parents, guardians or legal
                representatives, who will be held responsible for the acts carried out by the minors in
                their care, in accordance with the applicable regulations. In any case, it will be
                presumed that the sign-up to the platform www.ibrandapp.com or mobile application
                iBrand: Models Castings Events by a person of minor age has been made with the prior
                and express authorization of his/her parents, guardians or legal representatives. For
                preliminary information on the legal age in each country, see <a
                href="https://en.wikipedia.org/wiki/Age_of_majority">the attached Wikipedia page
            </a>
            </p>
            <p>2.4 The Model shall choose a password upon registration, which is needed in order to
                access his/her account on website www.ibrandapp.com and mobile application iBrand:
                Models Castings Events. The Model is obliged to keep this password secret. Website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events shall not
                disclose the password to any third party and website www.ibrandapp.com and mobile
                application iBrand: Models Castings Events’S employees shall not ask for the Model’s
                password at any time. The Model shall notify website www.ibrandapp.com and mobile
                application iBrand: Models Castings Events immediately of any unauthorized use of
                his/her password or account.</p>
            <p>
                2.5 By completing the registration process, the Model accepts these GTCs on the use
                of the services of website www.ibrandapp.com and mobile application iBrand: Models
                Castings Events. website www.ibrandapp.com and mobile application iBrand: Models
                Castings Events accepts this offer by activating the services on website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events.
            </p>
            <p>
                2.6 It is agreed that once the Model completes profile information such as gender,
                ethnicity, age etc, these will be shown publicly.
            </p>
            <p><b>3. Obligations of the Model</b></p>
            <p>3.1 The Model is obliged to provide only true and non-misleading statements in his/her
                profile and in communications with other users.</p>
            <p>3.2 The Model shall post on website www.ibrandapp.com and mobile application
                iBrand: Models Castings Events only photographs, videos, content and other materials
                (the &quot;Content&quot;) that are in compliance with the guidelines for photographs published by
                website www.ibrandapp.com and mobile application iBrand: Models Castings Events
                under &quot;guidelines&quot;. The Model warrants and represents that the public display of such
                Content is not prohibited. The Model agrees that Trusted Photographer may directly
                upload photos and videos of the Model that they take.</p>
            <p>3.3 The Content posted by the Model shall comply with all applicable legislation, and
                respect all third-party rights. In particular, the Model shall not: (i) post any pornographic,
                insulting or defamatory Contents, or any Contents that violate any applicable legislation
                for the protection of minors; (ii) advertise or promote, offer or distribute any products or
                services which do not comply with any applicable legislation for the protection of minors;
                (iii) use without authorization any Contents protected by law (e.g. by copyright,
                trademark, patent, utility patent, or design patent laws).</p>
            <p>
                3.4 The Model shall not distribute or publicly disclose the contents of website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events, or perform
                any actions which may impair the operability of website www.ibrandapp.com and mobile
                application iBrand: Models Castings Events’s infrastructure, particularly actions which
                may overload said infrastructure.
            </p>
            <p>
                3.5 The Model agrees that website www.ibrandapp.com and mobile application iBrand:
                Models Castings Events may use his/her name and a customer reference, e.g. in
                publications about success stories, and may publicly disclose and advertise the fact that
                the Model is using website www.ibrandapp.com and mobile application iBrand: Models
                Castings Events.
            </p>
            <p><b>4. Changes to the Services on website www.ibrandapp.com and mobile application iBrand: Models Castings
                Events</b></p>
            <p>
                website www.ibrandapp.com and mobile application iBrand: Models Castings Events
                reserves the right to modify the services offered on website www.ibrandapp.com and
                mobile application iBrand: Models Castings Events and/or to offer services different
                from those offered at the time of the registration of the Model at any time, unless this is
                unreasonable for the Model.
            </p>
            <p><b>5. Termination</b></p>
            <p>
                5.1 The Model may terminate his/her account to website to mobile application iBrand:
                Models Castings Events at any time without cause. The Model may delete the account
                visiting settings in the app and clicking on the button &quot;Delete account&quot;.
            </p>
            <p><b>6. Responsibility for the Model’s Content, Data or other Information</b></p>
            <p>
                6.1 website www.ibrandapp.com and mobile application iBrand: Models Castings
                Events does not make any warranties or representations regarding any data and/or
                information provided or made available by any user on website www.ibrandapp.com
                and mobile application iBrand: Models Castings Events. In particular, website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events does not
                warrant or represent that said data and/or information is true or accurate, or that it fulfils
                or serves any particular purpose.
            </p>
            <p>
                6.2 The Model shall report any activities of any other user which violate applicable
                laws, and/or any of these GTCs using the contact form available on website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events.
            </p>
            <p><b>7. Customer Service/Support</b></p>
            <p>
                Queries regarding the agreement with website www.ibrandapp.com and mobile
                application iBrand: Models Castings Events or regarding website www.ibrandapp.com
                and mobile application iBrand: Models Castings Events’s services may be sent to
                website www.ibrandapp.com and mobile application iBrand: Models Castings Events
                using the contact form available on website www.ibrandapp.com and mobile application
                iBrand: Models Castings Events or by sending an e-mail to info@ibrandapp.com.
            </p>
            <p><b>8. Liability of website www.ibrandapp.com and mobile application iBrand: Models
                Castings Events</b></p>
            <p>
                website www.ibrandapp.com and mobile application iBrand: Models Castings Events
                (including its vicarious agents) shall only be liable for damages suffered by the Model as
                a consequence of its account to website www.ibrandapp.com and mobile application
                iBrand: Models Castings Events if website www.ibrandapp.com and mobile application
                iBrand: Models Castings Events is in material breach of these GTCs and has not cured
                such breach (if capable of being cured) despite a reasonable cure period set by the
                Model.
            </p>
            <p><b>9. Indemnity</b></p>
            <p>
                9.1 The Model shall indemnify and hold website www.ibrandapp.com and mobile
                application iBrand: Models Castings Events harmless from and against all actions,
                including damage claims, asserted by other users or third parties against website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events resulting
                from an infringement of their rights by the Contents posted by the Model on website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events. The
                Model assumes all reasonable costs website www.ibrandapp.com and mobile
                application iBrand: Models Castings Events incurs due to an infringement of third party
                rights, including all reasonable legal-defense costs. All other rights of website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events, including
                the right to claim damages, shall remain unaffected.
            </p>
            <p>
                9.2 In the event the Contents posted by the Model infringes any rights of any third
                party, the Model shall, at his/her own expense and at website www.ibrandapp.com and
                mobile application iBrand: Models Castings Events’s discretion, either obtain the right to
                use said Contents or render said Contents free of any infringement.
            </p>
            <p><b>10. Data Protection</b></p>
            <p>
                Website www.ibrandapp.com and mobile application iBrand: Models Castings Events
                recognizes that any personal data provided by the Model to website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events is
                extremely important to the Model, and website www.ibrandapp.com and mobile
                application iBrand: Models Castings Events shall therefore be particularly sensitive in
                handling such data. Website www.ibrandapp.com and mobile application iBrand:
                Models Castings Events shall comply with all applicable legal provisions regarding data
                protection. In particular, website www.ibrandapp.com and mobile application iBrand:
                Models Castings Events shall not provide or otherwise disclose any personal data of the
                Model to any third party without authorization. Details on website www.ibrandapp.com
                and mobile application iBrand: Models Castings Events's treatment of the User's data
                are set forth in the Data Protection Policy of website www.ibrandapp.com and mobile
                application iBrand: Models Castings Events.
            </p>
            <p><b>11. Feedback, Reputation and reviews</b></p>
            <p>
                Models accept and acknowledge transferring copyright of all the feedback, reputation
                and reviews that they may post on the Website. This feedback belongs solely to website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events. Models
                acknowledge that they cannot use this feedback without prior written permission of
                website www.ibrandapp.com and mobile application iBrand: Models Castings Events.
            </p>
            <p><b>12. Taxes</b></p>
            <p>Models are responsible for paying their own taxes. Legal VAT, according to the Law of
                Ukraine, will be added to fees billed to Models, if applicable.</p>
            <p><b>13. Language</b></p>
            <p>Models accepting the GTCs for using website www.ibrandapp.com and mobile
                application iBrand: Models Castings Events declare that they fully and perfectly
                understand the content of the English version of these GTCs.</p>
            <p><b>14. Miscellaneous</b></p>
            <p>14.1 Any amendments to these GTCs must be made in writing in order to be valid.</p>
            <p>
                14.2 Website www.ibrandapp.com and mobile application iBrand: Models Castings
                Events reserves the right to amend these GTCs at any time, without giving reasons,
                unless an amendment is unreasonable to the Model. website www.ibrandapp.com and
                mobile application iBrand: Models Castings Events shall give due notice of any
                amendments of these GTCs to the Model. If the Model does not object to the
                applicability of the revised GTCs within two (2) weeks after receipt of said notice, the
                amended GTCs shall be deemed to be accepted by the Model. website
                www.ibrandapp.com and mobile application iBrand: Models Castings Events shall
                inform the Model about the Model's right to object and of the relevance of the objection
                deadline in said notice.
            </p>
            <p>
                14.3 Unless otherwise stated in these GTCs, the Model may submit all notices to
                website www.ibrandapp.com and mobile application iBrand: Models Castings Events
                using the contact form provided on website www.ibrandapp.com and mobile application
                iBrand: Models Castings Events, or by sending an e-mail to info@ibrandapp.com
            </p>
            <p>
                14.4 If any provision of these GTCs is, for any reason, invalid and/or unenforceable,
                the remaining provisions shall continue to be valid and enforceable to the fullest extent
                permitted by law. The parties agree to replace an invalid and/or unenforceable provision
                with a valid and/or enforceable provision which comes as close as possible to the intent
                and economic purpose of the invalid and/or unenforceable provision. This also applies
                to regulatory gaps in these GTCs.
            </p>
        </div>
    );
}

const TermsRU = () => {
    return (
        <div className="terms-content">
            <h2>Общие условия использования сайта www.ibrandapp.com и мобильного приложения iBrand: Models Castings
                Events</h2>
            <p>
                Заходя на веб-сайт www.ibrandapp.com и мобильное приложение iBrand: Models
                Castings Events, пользователи принимают следующие Общие положения и
                условия (далее «ОУП») для использования веб-сайта www.ibrandapp.com и
                мобильного приложения iBrand: Models Castings Events. Пользователь заключает
                настоящее соглашение об использовании сайта www.ibrandapp.com и мобильного
                приложения iBrand: Models Castings Events с IBrand Development OÜ, Kamanina tänav, 16a/1, Odessa,
                Ukraine, 65000. Доп. контактная информация, данные коммерческого реестра,
                а также имя уполномоченного представителя сайта www.ibrandapp.com и
                мобильного приложения iBrand: Models Castings Events доступны через <b>&quot;Политику
                конфиденциальности&quot;.</b>
            </p>

            <h2>А. ОПРЕДЕЛЕНИЯ</h2>
            <p><b>&quot;Заказчики&quot;:</b> Фотографы, бренды, модельные агентства, кастинг-директора,
                рекламные агентства, продюсерские компании, СМИ, шоу-румы, парикмахеры и
                визажисты, PR/мероприятия, другие специалисты отрасли и зрители,
                посещающие или использующие веб-сайт www.ibrandapp.com или мобильное
                приложение iBrand. : Модели Кастинги Мероприятия;</p>
            <p><b>&quot;Модели&quot;:</b> таланты, посещающие или использующие веб-сайт
                www.ibrandapp.com и мобильное приложение iBrand: Models Castings Events;</p>
            <p><b>&quot;GTCs&quot;:</b> Общие положения и условия использования веб-сайта
                www.ibrandapp.com и мобильного приложения iBrand: Models Castings Events</p>
            <p><b>&quot;Веб-сайт&quot;:</b> www.ibrandapp.com</p>
            <p><b>&quot;Мобильное приложение&quot;:</b> iBrand: Models Castings Events</p>

            <h2>B. ИСПОЛЬЗОВАНИЕ веб-сайта www.ibrandapp.com и мобильного приложения iBrand: Models Castings Events</h2>

            <p><b>1. Предмет</b></p>
            <p>
                1.1 веб-сайт www.ibrandapp.com и мобильное приложение iBrand: Models Castings
                Events предлагает Моделям платформу для представления своих профилей и
                фотокниг, а также для установления деловых контактов, в частности, с
                агентами/агентствами и фотографами.
            </p>
            <p>
                1.2 Модель признает и соглашается с тем, что технически невозможно обеспечить
                100% доступность веб-сайта www.ibrandapp.com и мобильного приложения iBrand:
                Models Castings Events. веб-сайт www.ibrandapp.com и мобильное приложение
                iBrand: Models Castings Events должны, тем не менее, стремиться обеспечить
                непрерывную доступность веб-сайта www.ibrandapp.com и мобильного
                приложения iBrand: Models Castings Events. События, связанные с техническим
                обслуживанием, требованиями безопасности или пропускной способности, и/или
                события, выходящие за рамки веб-сайта www.ibrandapp.com и мобильного
                приложения iBrand: Models Castings Events Управление событиями (например,
                сбои в сетях связи общего пользования, перебои в подаче электроэнергии и т. д.)
                могут привести к кратковременным сбоям или временные перерывы в работе
                сервисов, предоставляемых на сайте www.ibrandapp.com и в мобильном
                приложении iBrand: Models Castings Events.
            </p>
            <p>
                1.3 Веб-сайт www.ibrandapp.com и мобильное приложение iBrand: Models Castings
                Events предоставляет Моделям платформу для установления контактов с другими
                лицами или организациями, в частности модельными агентствами. веб-сайт
                www.ibrandapp.com и мобильное приложение iBrand: Models Castings Events не
                принимает участия в каком-либо общении между Моделями и/или любыми
                другими пользователями своих услуг. Если пользователи заключают соглашения
                друг с другом через веб-сайт www.ibrandapp.com и мобильное приложение iBrand:
                Models Castings Events, веб-сайт www.ibrandapp.com и мобильное приложение
                iBrand: Models Castings Events не являются договаривающейся стороной этих
                соглашений. Только пользователи несут ответственность за выполнение и/или
                выполнение соглашений, в которых они заключают друг с другом. веб-сайт
                www.ibrandapp.com и мобильное приложение iBrand: Models Castings Events не
                несет ответственности, если пользователи не могут связаться друг с другом через
                веб-сайт www.ibrandapp.com и мобильное приложение iBrand: Models Castings
                Events относительно таких соглашений. Кроме того, сайт www.ibrandapp.com и
                мобильное приложение iBrand: Models Castings Events не несут ответственности
                за нарушение соглашений, заключенных между пользователями.
            </p>
            <p><b>2. Регистрация в мобильном приложении iBrand: Models Castings Events</b>
            </p>
            <p>2.1 Модели должны зарегистрироваться перед использованием услуг сайта
                www.ibrandapp.com или мобильного приложения iBrand: Models Castings Events.</p>
            <p>
                2.2 Модель должна предоставлять только точные и полные данные для
                регистрации. Регистрационные данные включают псевдоним (онлайн) имя,
                которое будет выбрано Моделью для публичного показа с профилем Модели.
                Модель должна сообщать о любых изменениях в регистрационных данных на вебсайт www.ibrandapp.com и в
                мобильное приложение iBrand: Models Castings Events
                без неоправданной задержки.
            </p>
            <p>
                2.3 Регистрация на платформе лиц несовершеннолетнего возраста не
                допускается, если они не имеют предварительного и явного разрешения своих
                родителей, опекунов или законных представителей, которые будут нести
                ответственность за действия, совершенные несовершеннолетними,
                находящимися под их опекой. , в соответствии с действующими нормами. В
                любом случае предполагается, что регистрация на платформе www.ibrandapp.com
                или в мобильном приложении iBrand: Models Castings Events несовершеннолетним
                лицом была осуществлена с предварительного и явного разрешения его/ее
                родителей, опекуны или законные представители. Предварительную информацию
                о совершеннолетии в каждой стране см. на <a
                href="https://en.wikipedia.org/wiki/Age_of_majority">прикрепленной странице Википедии.
            </a>
            </p>
            <p>
                2.4 Модель при регистрации выбирает пароль, необходимый для доступа к своей
                учетной записи на сайте www.ibrandapp.com и в мобильном приложении iBrand:
                Models Castings Events. Модель обязана хранить этот пароль в тайне. Веб-сайт
                www.ibrandapp.com и мобильное приложение iBrand: Models Castings Events не
                должны раскрывать пароль третьим лицам, а веб-сайт www.ibrandapp.com и
                мобильное приложение iBrand: Models Castings Events не должны запрашивать
                пароль у Модели в любое время. Модель должна немедленно уведомить веб-сайт
                www.ibrandapp.com и мобильное приложение iBrand: Models Castings Events о
                любом несанкционированном использовании своего пароля или учетной записи.
            </p>
            <p>
                2.5 Завершив процесс регистрации, Модель принимает настоящие Общие условия
                использования услуг веб-сайта www.ibrandapp.com и мобильного приложения
                iBrand: События кастинга моделей. сайт www.ibrandapp.com и мобильное
                приложение iBrand: Models Castings Events принимает данное предложение,
                активировав услуги на сайте www.ibrandapp.com и мобильном приложении iBrand:
                Models Castings Events.
            </p>
            <p>
                2.6 Согласовано, что после того, как Модель заполнит информацию профиля,
                такую как пол, этническая принадлежность, возраст и т. д., они будут показаны
                публично.
            </p>
            <p><b>3. Обязанности Модели</b></p>
            <p>
                3.1 Модель обязана предоставлять только правдивые и не вводящие в
                заблуждение заявления в своем профиле и в общении с другими пользователями.
            </p>
            <p>
                3.2 Модель размещает на веб-сайте www.ibrandapp.com и в мобильном
                приложении iBrand: Models Castings Events только фотографии, видео, контент и
                другие материалы («Контент»), соответствующие правилам размещения
                фотографий, опубликованным на веб-сайте www.ibrandapp.com и мобильное
                приложение iBrand: Models Castings Events, Мероприятия под «рекомендациями».
                Модель гарантирует и заявляет, что публичный показ такого Контента не
                запрещен. Модель соглашается с тем, что доверенный фотограф может
                напрямую загружать фотографии и видео Модели, которые они снимают.
            </p>
            <p>
                3.3 Контент, размещенный Моделью, должен соответствовать всему
                применимому законодательству и уважать все права третьих лиц. В частности,
                Модель не должна: (i) размещать порнографический, оскорбительный или
                клеветнический Контент или любой Контент, нарушающий применимое
                законодательство о защите несовершеннолетних; (ii) рекламировать или
                продвигать, предлагать или распространять любые продукты или услуги, которые
                не соответствуют применимому законодательству о защите несовершеннолетних;
                (iii) использовать без разрешения любое Содержимое, защищенное законом
                (например, законами об авторских правах, товарных знаках, патентах, патентах на
                полезные модели или патентах на промышленные образцы).
            </p>
            <p>
                3.4 Модель не должна распространять или публично разглашать содержимое
                сайта www.ibrandapp.com и мобильного приложения iBrand: Models Castings
                Events, а также совершать любые действия, которые могут нарушить
                работоспособность сайта www.ibrandapp.com и мобильного приложения iBrand:
                Models Castings Events Инфраструктура событий, особенно действия, которые
                могут перегрузить указанную инфраструктуру.
            </p>
            <p>
                3.5 Модель соглашается с тем, что веб-сайт www.ibrandapp.com и мобильное
                приложение iBrand: Models Castings Events могут использовать его/ее имя и
                ссылку на клиента, например, в публикациях об историях успеха, а также могут
                публично раскрывать и рекламировать тот факт, что Модель использует сайт
                www.ibrandapp.com и мобильное приложение iBrand: Models Castings Events.
            </p>
            <p><b>4. Изменения в Услугах на сайте www.ibrandapp.com и в мобильном
                приложении iBrand: Models Castings Events</b></p>
            <p>
                веб-сайт www.ibrandapp.com и мобильное приложение iBrand: Models Castings
                Events оставляет за собой право изменять услуги, предлагаемые на веб-сайте
                www.ibrandapp.com и мобильном приложении iBrand: Models Castings Events, и/или
                предлагать услуги, отличные от тех, которые предлагались на данный момент.
                регистрации Модели в любое время, если это не является необоснованным для
                Модели.
            </p>
            <p><b>5. Прекращение</b></p>
            <p>
                5.1 Модель может удалить свою учетную запись на веб-сайте в мобильном
                приложении iBrand: Models Castings Events в любое время без объяснения причин.
                Модель может удалить учетную запись, зайдя в настройки приложения и нажав на
                кнопку «Удалить учетную запись».
            </p>
            <p><b>6. Ответственность за Контент, Данные или другую Информацию Модели</b></p>
            <p>
                6.1 веб-сайт www.ibrandapp.com и мобильное приложение iBrand: Models Castings
                Events не дает никаких гарантий или заявлений относительно любых данных и/или
                информации, предоставленных или сделанных доступными любым
                пользователем на веб-сайте www.ibrandapp.com и в мобильном приложении
                iBrand: Models Castings. События. В частности, веб-сайт www.ibrandapp.com и
                мобильное приложение iBrand: Models Castings Events не гарантируют и не
                заявляют, что указанные данные и/или информация являются достоверными или
                точными, или что они выполняют или служат какой-либо конкретной цели.
            </p>
            <p>
                6.2 Модель должна сообщать о любых действиях любого другого пользователя,
                которые нарушают действующее законодательство и/или любые из этих Общих
                условий использования, используя контактную форму, доступную на веб-сайте
                www.ibrandapp.com и в мобильном приложении iBrand: События кастинга моделей.
            </p>
            <p><b>7. Обслуживание клиентов/поддержка</b></p>
            <p>
                Запросы по соглашению с сайтом www.ibrandapp.com и мобильным приложением
                iBrand: Models Castings Events или по сайту www.ibrandapp.com и мобильному
                приложению iBrand: услуги Models Castings Events можно направлять на сайт
                www.ibrandapp.com и мобильное приложение iBrand : Кастинги моделей,
                используя контактную форму на веб-сайте www.ibrandapp.com и мобильное
                приложение iBrand: События кастингов моделей или отправив электронное
                письмо на адрес info@ibrandapp.com.
            </p>
            <p><b>8. Ответственность сайта www.ibrandapp.com и мобильного приложения
                iBrand: Models Castings Events</b></p>
            <p>
                Веб-сайт www.ibrandapp.com и мобильное приложение iBrand: Models Castings
                Events (включая его доверенных лиц) несет ответственность только за ущерб,
                понесенный Моделью в результате ее учетной записи на веб-сайте
                www.ibrandapp.com и в мобильном приложении iBrand: Models Castings. События,
                если веб-сайт www.ibrandapp.com и мобильное приложение iBrand: Models
                Castings Events существенно нарушают настоящие ОУП и не устранили такое
                нарушение (если оно может быть устранено), несмотря на разумный период
                устранения, установленный Моделью.
            </p>
            <p><b>9. Возмещение</b></p>
            <p>
                9.1 Модель возмещает ущерб и ограждает веб-сайт www.ibrandapp.com и
                мобильное приложение iBrand: Models Castings Events от любых действий,
                включая иски о возмещении ущерба, заявленные другими пользователями или
                третьими лицами в отношении веб-сайта www.ibrandapp.com и мобильного
                приложения iBrand: Models Castings Events в результате нарушения их прав
                Контентом, размещенным Моделью на сайте www.ibrandapp.com и в мобильном
                приложении iBrand: События Кастингов Моделей. Модель берет на себя все
                разумные расходы, которые веб-сайт www.ibrandapp.com и мобильное
                приложение iBrand: Models Castings Events понесет в связи с нарушением прав
                третьих лиц, включая все разумные расходы на юридическую защиту. Все
                остальные права веб-сайта www.ibrandapp.com и мобильного приложения iBrand:
                Models Castings Events, включая право требовать возмещения убытков, остаются
                в силе.
            </p>
            <p>
                9.2 В случае, если Контент, размещенный Моделью, нарушает какие-либо права
                какой-либо третьей стороны, Модель должна за свой счет и по усмотрению вебсайта www.ibrandapp.com и
                мобильного приложения iBrand: Models Castings Events
                либо получить право использовать указанное Содержимое или сделать указанное
                Содержимое свободным от каких-либо нарушений.
            </p>
            <p><b>10. Защита данных</b></p>
            <p>
                Веб-сайт www.ibrandapp.com и мобильное приложение iBrand: Models Castings
                Events признает, что любые персональные данные, предоставленные Моделью
                веб-сайту www.ibrandapp.com и мобильному приложению iBrand: Models Castings
                Events, чрезвычайно важны для Модели и веб-сайта www.ibrandapp. Поэтому .com
                и мобильное приложение iBrand: Models Casting Events должны быть особенно
                конфиденциальны при обработке таких данных. Веб-сайт www.ibrandapp.com и
                мобильное приложение iBrand: Models Castings Events соблюдают все
                применимые правовые положения, касающиеся защиты данных. В частности, вебсайт www.ibrandapp.com и
                мобильное приложение iBrand: Models Castings Events
                не должны предоставлять или иным образом раскрывать какие-либо
                персональные данные Модели какой-либо третьей стороне без разрешения.
                Подробности на сайте www.ibrandapp.com и в мобильном приложении iBrand:
                Models Castings Events Обращение с данными Пользователя изложено в Политике
                защиты данных сайта www.ibrandapp.com и мобильного приложения iBrand:
                Models Castings Events.
            </p>
            <p><b>11. Обратная связь, репутация и отзывы</b></p>
            <p>
                Модели принимают и подтверждают передачу авторских прав на все отзывы,
                репутацию и обзоры, которые они могут публиковать на Веб-сайте. Этот отзыв
                принадлежит исключительно веб-сайту www.ibrandapp.com и мобильному
                приложению iBrand: Models Castings Events. Модели признают, что не могут
                использовать этот отзыв без предварительного письменного разрешения вебсайта www.ibrandapp.com и
                мобильного приложения iBrand: Models Castings
                Events.
            </p>
            <p><b>12. Налоги</b></p>
            <p>Модели несут ответственность за уплату собственных налогов. Юридический
                НДС, в соответствии с Законом Украины, будет добавлен к вознаграждению,
                выставленному Модели, если это применимо.</p>
            <p><b>13. Язык</b></p>
            <p>Модели, принимающие правила для использования веб-сайта www.ibrandapp.com
                и мобильного приложения iBrand: Models Castings Events заявляют, что они
                полностью и в совершенстве понимают содержание англоязычной версии данных.</p>
            <p><b>14. Разное</b></p>
            <p>14.1 Любые поправки к этим GTC должны быть сделаны в письменной форме,
                чтобы быть действительными.</p>
            <p>
                14.2 Веб-сайт www.ibrandapp.com и мобильное приложение iBrand: Models
                Castings Events оставляет за собой право вносить изменения в настоящие ОУП в
                любое время без объяснения причин, за исключением случаев, когда изменение
                является необоснованным для Модели. веб-сайт www.ibrandapp.com и мобильное
                приложение iBrand: Models Castings Events должны надлежащим образом
                уведомлять о любых поправках настоящих ОУП к Модели. Если Модель не
                возражает против применения пересмотренных ОУ в течение двух (2) недель
                после получения указанного уведомления, измененные ОУ считаются принятыми
                Моделью. веб-сайт www.ibrandapp.com и мобильное приложение iBrand: Models
                Castings Events должны информировать Модель о праве Модели на возражение и
                актуальности крайнего срока возражения в указанном уведомлении.
            </p>
            <p>
                14.3 Если в настоящих ОУП не указано иное, Модель может отправлять все
                уведомления на веб-сайт www.ibrandapp.com и в мобильное приложение iBrand:
                Models Castings Events, используя контактную форму, представленную на вебсайте www.ibrandapp.com, и в
                мобильное приложение iBrand: Models Castings
                Events, или отправив электронное письмо на адрес info@ibrandapp.com.
            </p>
            <p>
                14.4 Если какое-либо положение настоящих ОПУ по какой-либо причине
                является недействительным и/или не имеющим исковой силы, остальные
                положения остаются в силе и подлежат исполнению в максимальной степени,
                разрешенной законом. Стороны соглашаются заменить недействительное и/или
                неисполнимое положение действительным и/или подлежащим исполнению
                положением, которое максимально соответствует намерению и экономической
                цели недействительного и/или неисполнимого положения. Это также относится к
                нормативным пробелам в этих GTC.
            </p>
        </div>
    );
}
