import React, {Component} from 'react';
import {HomeContainer} from "./components/home/Container";
import {withTranslation} from 'react-i18next';
import {imageResources} from "./imagePreloader";
import {Helmet} from "react-helmet";

interface AppInterface {
    images: any
    mounted: boolean
    animateSpinner: boolean
    preloaded: number
}

class App extends Component<{}, AppInterface> {

    constructor(props: any) {
        super(props);
        this.state = {images: [], mounted: false, animateSpinner: false, preloaded: 0}
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Работа моделью мечты с iBrand</title>
                    <meta name="description"
                          content="iBrand — работа моделью для профессионалов и начинающих от лучших брендов, продюсеров, агентств и фотографов."/>
                    <meta name="keywords" content="работа моделью"/>
                </Helmet>
                <HomeContainer images={imageResources}/>
            </React.Fragment>
        );
    }
}

export default withTranslation()(App);
