import React, {Component} from "react";
import desktop from "../../../assets/home/investments/investments.png";
import mobile from "../../../assets/home/investments/investments_mobile.png";
import {Trans} from "react-i18next";
import "./Investments.scss"

export default class Investments extends Component {

    render() {
        return (
            <div className="investments">
                <h4 className="investments__title mobile-only">Investments</h4>
                <picture className="investments__image">
                    <source srcSet={desktop} media="(min-width: 64rem)"/>
                    <img src={mobile}/>
                </picture>
                <div className="investments__text">
                    <h4 className="investments__title desktop-only">Investments</h4>
                    <p>
                        <Trans i18nKey="home.investments.text"/>
                    </p>
                </div>
            </div>
        );
    }
}