import React, {Component} from "react";
import {Trans} from "react-i18next";
import {ReactComponent as HeaderLogo} from '../../../assets/header/header-logo.svg';
import appStore from '../../../assets/header/app-store.svg';
import appStoreHover from '../../../assets/header/app-store-hover.svg';
import playMarket from '../../../assets/header/play-market.svg';
import playMarketHover from '../../../assets/header/play-market-hover.svg';
import {ReactComponent as Burger} from '../../../assets/header/burger.svg';
import {ReactComponent as Cross} from '../../../assets/header/cross.svg';
import {ReactComponent as BrandLogo} from '../../../assets/footer/footer-logo.svg';
import {ReactComponent as Vector} from '../../../assets/header/Vector.svg';
import {LanguageSelector} from "../LanguageSelector";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {Link} from "react-router-dom";
import './Header.scss';

interface HeaderState {
    isMenuOpened: boolean
    appStoreIconHovered: boolean
    playMarketIconHovered: boolean
    currentAnchor: string
    isOnTop: boolean
}

export class Header extends Component<{}, HeaderState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isMenuOpened: false,
            appStoreIconHovered: false,
            playMarketIconHovered: false,
            currentAnchor: "",
            isOnTop: document.documentElement.scrollTop === 0
        };
    }

    componentDidMount() {
        window.onscroll = () => {
            this.setState({isOnTop: document.documentElement.scrollTop === 0});
        };
    }

    getMenuIcon() {
        return this.state.isMenuOpened ? <Cross/> : <Burger/>
    }

    onClick = () => {
        this.setState({isMenuOpened: !this.state.isMenuOpened});
    }

    onAppStoreIconHovered = () => {
        this.setState({appStoreIconHovered: !this.state.appStoreIconHovered});
    }

    onPlayMarketIconHovered = () => {
        this.setState({playMarketIconHovered: !this.state.playMarketIconHovered});
    }

    getHamburgerMenuItem(id: string, withIcon?: boolean) {
        return (
            <li className={`header__hamburger-menu-list-item ${this.state.currentAnchor === id ? "header__hamburger-menu-list-item--active" : ""}`}>
                <AnchorLink className="header__navigation-item" href={`#${id}`}
                            onClick={() => this.setState({isMenuOpened: false, currentAnchor: id})}>
                    {withIcon ? <span className="header__navigation-item--icon"><Vector/></span> : ""}
                    <Trans i18nKey={`header.${id}.link`}/>
                </AnchorLink>
            </li>
        );
    }

    render() {
        return (
            <header className={`header ${this.state.isOnTop ? "header--top" : ""}`}>
                <div className="header__main-logo" onClick={() => window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                })}>
                    <HeaderLogo/>
                </div>
                <div className="header__navigation">
                    <AnchorLink className="header__navigation-item" href="#models">
                        <Trans i18nKey="header.models.link"/>
                    </AnchorLink>
                    <AnchorLink className="header__navigation-item" href="#producer">
                        <Trans i18nKey="header.producer.link"/>
                    </AnchorLink>
                    <AnchorLink className="header__navigation-item" href="#about">
                        <Trans i18nKey="header.about.link"/>
                    </AnchorLink>
                    <AnchorLink className="header__navigation-item" href="#ambassador">
                        <span className="header__navigation-item--icon"><Vector/></span>
                        <Trans i18nKey="header.ambassador.link"/>
                    </AnchorLink>
                    <Link className="header__navigation-item" to="/commercial-offer">Offer</Link>
                    <AnchorLink className="header__navigation-item" href="#top">
                        <Trans i18nKey="header.top.link"/>
                    </AnchorLink>
                    <AnchorLink className="header__navigation-item" href="#contacts">
                        <Trans i18nKey="header.contacts.link"/>
                    </AnchorLink>
                </div>
                <div className="header__actions">
                    <div className="header__actions-common">
                        <a className="header__actions-icon" target="_blank" rel="noreferrer"
                           href="https://apps.apple.com/ua/app/ibrand-models-castings-events/id1608781339"
                           onMouseEnter={this.onAppStoreIconHovered}
                           onMouseLeave={this.onAppStoreIconHovered}>
                            <img src={this.state.appStoreIconHovered ? appStoreHover : appStore}
                                 alt="Download from App Store"/>
                        </a>
                        <a className="header__actions-icon" target="_blank" rel="noreferrer"
                           href="https://play.google.com/store/apps/details?id=com.ibrandapp"
                           onMouseEnter={this.onPlayMarketIconHovered}
                           onMouseLeave={this.onPlayMarketIconHovered}>
                            <img src={this.state.playMarketIconHovered ? playMarketHover : playMarket}
                                 alt="Download from Play Market"/>
                        </a>
                        <LanguageSelector/>
                    </div>
                    <span className="header__actions-icon menu-icon" onClick={this.onClick}>{this.getMenuIcon()}</span>
                </div>
                <div className={`header__hamburger-menu ${this.state.isMenuOpened ? "show" : "close"}`}>
                    <ul className="header__hamburger-menu-list">
                        {this.getHamburgerMenuItem("models")}
                        {this.getHamburgerMenuItem("producer")}
                        {this.getHamburgerMenuItem("about")}
                        {this.getHamburgerMenuItem("ambassador", true)}
                        <li className="header__hamburger-menu-list-item">
                            <Link className="header__navigation-item" to="/commercial-offer">Offer</Link>
                        </li>
                        {this.getHamburgerMenuItem("top")}
                        {this.getHamburgerMenuItem("contacts")}
                    </ul>
                    <div className="header__hamburger-menu-logo">
                        <BrandLogo/>
                    </div>
                </div>
            </header>
        );
    }
}