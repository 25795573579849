import {Component} from "react";

import "./About.scss"
import {Trans} from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import {getImage} from "../../../imagePreloader";

interface AboutProps {
    images: any
}

interface AboutState {
    left: number
}

export class About extends Component<AboutProps, AboutState> {

    constructor(props: AboutProps) {
        super(props);

        this.state = {left: 0};
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.innerWidth < 768) {
            this.setState({left: Math.max(Math.max(window.innerWidth / 2) - 0.05 * window.scrollY)})
        } else {
            this.setState({left: Math.max(Math.max(window.innerWidth / 4) - 0.1 * window.scrollY)})
        }
    }

    getContent() {
        return Array.from(Array(5).keys()).map(value => (
            <div key={value} className="about__content-row">
                <span className="about__content-row-counter">{value + 1}</span>
                <span className="about__content-row-text"><Trans i18nKey={`about.${value + 1}.message`}/></span>
            </div>
        ));
    }

    render() {
        return (
            <div className="about" id="about">
                <div className="about__title-container desktop-only">
                    <h2 className="about__background" style={{transform: `translate(${this.state.left}px)`}}>
                        Innovation
                    </h2>
                    <ScrollAnimation className="about__title" animateIn="animate_visible">
                        <h4 className="about__title">Project</h4>
                    </ScrollAnimation>
                </div>
                <div className="about__wrapper">
                    <ScrollAnimation className="about__image-container" animateIn="animate_sliding" animateOnce={true}>
                        <img className="about__image" src={getImage(this.props.images, "screens")}
                             alt="Screens example"/>
                    </ScrollAnimation>
                    <div className="about__title-container mobile-only">
                        <h2 className="about__background" style={{transform: `translate(${this.state.left}px)`}}>
                            Innovation
                        </h2>
                        <h4 className="about__title">Project</h4>
                    </div>

                    <ScrollAnimation animateIn="animate_list" className="about__content" animateOnce={true}>
                        {this.getContent()}
                        <span className="about__content-black"/>
                    </ScrollAnimation>
                </div>
            </div>
        );
    }
}