import React, {Component} from "react";
import welcome from "./../../../assets/offer/recognition/welcome.png"
import registration from "./../../../assets/offer/recognition/registration.png"
import "./Recognition.scss"
import {Trans} from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

export default class Recognition extends Component {

    render() {
        return (
            <div className="offer-component offer-recognition">
                <div className="offer-component__wrapper">
                    <div className="offer-component__li">
                        <span className="square"></span>
                        <div className="thread">
                            <div className="thread-inner"/>
                        </div>
                    </div>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                        <h4 className="offer-component__heading"><Trans i18nKey="offer.recognition.title"/></h4>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true} delay={200}>
                        <p className="offer-component__subheading">
                            <Trans i18nKey="offer.recognition.description"/>
                        </p>
                    </ScrollAnimation>
                </div>
                <div className="offer-recognition__content">
                    <img src={welcome} className="offer-recognition__image offer-recognition__image--first"/>
                    <div>
                        <img src={registration} className="offer-recognition__image offer-recognition__image--second"/>
                        <h2 className="offer-recognition__message">
                            <Trans i18nKey="offer.recognition.your.photo"/>
                        </h2>
                    </div>
                </div>
                <h4 className="offer-recognition__card">
                    Event fashion app
                </h4>
            </div>
        );
    }
}