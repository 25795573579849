import brandLogo from "./assets/home/major/BrandLogo.png";
import background from "./assets/home/major/fog-1.png";
import mobile from "./assets/home/major/mobile.png";
import producers from "./assets/home/major/producers-screen.png";
import models from "./assets/home/major/models-screen.png";
import appStore from "./assets/common/app-store.png";
import appStoreHover from "./assets/common/app-store-hover.png";
import playMarket from "./assets/common/play-market.png";
import playMarketHover from "./assets/common/play-market-hover.png";
import forModel from "./assets/home/models/models.png"
import forPhotoshooting from "./assets/home/models/photoshooting.png"
import producerImage from "./assets/home/producer/producer.png"
import screens from "./assets/home/about/screens.png";
import credoImage from "./assets/home/credo/Credo-Image.png"

export const imageResources = [
    {
        name: "majorBrandLogo", source: brandLogo
    }, {
        name: "majorBackground", source: background
    }, {
        name: "majorMobile", source: mobile
    }, {
        name: "majorProducer", source: producers
    }, {
        name: "majorModels", source: models
    }, {
        name: "appStore", source: appStore
    }, {
        name: "appStoreHover", source: appStoreHover
    }, {
        name: "playMarket", source: playMarket
    }, {
        name: "playMarketHover", source: playMarketHover
    }, {
        name: "forModel", source: forModel
    }, {
        name: "forPhotoshooting", source: forPhotoshooting
    }, {
        name: "producerImage", source: producerImage
    }, {
        name: "screens", source: screens
    }, {
        name: "credoImage", source: credoImage
    }
];

export function preloadImages(callback: any) {
    return imageResources.map(image => new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            resolve({
                name: image.name,
                source: image.source
            });
            callback.call();
        }
        img.src = image.source;
    }));
}

export function getImage(images: any[], name: string) {
    return images.find(value => value.name === name).source;
}