import React, {Component, Fragment} from "react";
import {Footer} from "../../common/Footer";
import OfferIntroduction from "../Introduction";
import OfferCredo from "../Credo";
import SocialMedia from "../SocialMedia";
import Premium from "../Premium";
import Verified from "../Verified";
import BrandFaceIntro from "../BrandFaceIntro";
import Recognition from "../Recognition";
import OpinionLeader from "../OpinionLeader";
import OfferAmbassador from "../OfferAmbassador";
import {OfferHeader} from "../OfferHeader";
import "./OfferContainer.scss"
import {withTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

class CommercialOfferContainer extends Component {

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Offer - iBrand</title>
                    <meta name="description"
                          content="iBrand — работа моделью для профессионалов и начинающих от лучших брендов, продюсеров, агентств и фотографов."/>
                    <meta name="keywords" content="стать амбассадором"/>
                </Helmet>
                <OfferHeader/>
                <OfferIntroduction/>
                <BrandFaceIntro/>
                <Recognition/>
                <OpinionLeader/>
                <OfferAmbassador/>
                <Verified/>
                <Premium/>
                <SocialMedia/>
                <OfferCredo/>
                <Footer/>
            </Fragment>
        );
    }
}

export default withTranslation()(CommercialOfferContainer);