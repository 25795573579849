import React, {Component} from "react";
import mobile from "./../../../assets/offer/premium/mobile.png"
import desktop from "./../../../assets/offer/premium/desctop.png"
import "./Premium.scss"
import {Trans} from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

export default class Premium extends Component {

    render() {
        return (
            <div className="offer-component offer-premium">
                <div className="offer-component__wrapper">
                    <div className="offer-component__li">
                        <span className="square"></span>
                        <div className="thread">
                            <div className="thread-inner"/>
                        </div>
                    </div>
                    <div className="offer-premium__text">
                        <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                            <h4 className="offer-component__heading"><Trans i18nKey="offer.premium.title"/></h4>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate_visible" animateOnce={true} delay={200}>
                            <p className="offer-component__subheading">
                                <Trans i18nKey="offer.premium.description"/>
                            </p>
                        </ScrollAnimation>
                    </div>
                </div>
                <picture className="offer-premium__image">
                    <source srcSet={desktop} media="(min-width: 64rem)"/>
                    <img src={mobile}/>
                </picture>
            </div>
        );
    }
}