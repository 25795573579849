import React from 'react';
import {render} from 'react-dom';
import './index.css';
import App from './App';
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import translationEN from './locales/en.json';
import translationUA from './locales/ua.json';
import translationRU from './locales/ru.json';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import CommercialOfferContainer from "./components/offer/Container";
import {PolicyPage} from "./components/policy/Policy";
import {TermsPage} from "./components/terms/Terms";

async function i18nSetup() {
    const resources = {
        en: {
            translation: translationEN
        },
        ua: {
            translation: translationUA
        },
        ru: {
            translation: translationRU
        }
    };

    return i18n
        .use(initReactI18next)
        .use(detector)
        .init({
            resources,
            fallbackLng: "en",
            keySeparator: false,
            interpolation: {
                format: function (value) {
                    return value;
                },
            }
        });
}


document.addEventListener("DOMContentLoaded", () => {
    i18nSetup().then(() => {
        render(
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App/>}/>
                    <Route path="commercial-offer" element={<CommercialOfferContainer/>}/>
                    <Route path="policy" element={<PolicyPage/>}/>
                    <Route path="terms" element={<TermsPage/>}/>
                </Routes>
            </BrowserRouter>,
            document.getElementById('root')
        );
    });
});
