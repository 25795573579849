import React, {Component} from "react";
import ambassadors from "./../../../assets/offer/brandFaceIntro/ambassadors.png"
import "./BrandFaceIntro.scss"
import {Trans} from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

export default class BrandFaceIntro extends Component {

    render() {
        return (
            <div className="offer-component offer-intro">
                <div className="offer-component__wrapper">
                    <div className="offer-component__li">
                        <span className="square"></span>
                        <div className="thread">
                            <div className="thread-inner"/>
                        </div>
                    </div>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                        <h4 className="offer-component__heading"><Trans i18nKey="offer.intro.title"/></h4>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true} delay={200}>
                        <p className="offer-component__subheading">
                            <Trans i18nKey="offer.intro.description"/>
                        </p>
                    </ScrollAnimation>
                    <img className="offer-intro__image" src={ambassadors}/>
                    <h2 className="offer-intro__hashtag">#iBrandFace</h2>
                </div>
            </div>
        );
    }
}