import React, {Component} from "react";
import Slider from "react-slick";
import bodyActive from "../../../assets/home/partners/bodyengineers-active.png";
import bodyInactive from "../../../assets/home/partners/bodyengineers-inactive.png";
import inventureActive from "../../../assets/home/partners/inventure-active.png";
import inventureInactive from "../../../assets/home/partners/inventure-inactive.png";
import ohuenoActive from "../../../assets/home/partners/ohueno-active.png";
import ohuenoInactive from "../../../assets/home/partners/ohueno-inactive.png";
import polivicActive from "../../../assets/home/partners/polivic-active.png";
import polivicInactive from "../../../assets/home/partners/polivic-inactive.png";
import voloActive from "../../../assets/home/partners/volo-active.png";
import voloInactive from "../../../assets/home/partners/volo-inactive.png";
import "./Partners.scss"

const partners: any[] = [
    {
        "active": bodyActive,
        "inactive": bodyInactive
    },
    {
        "active": inventureActive,
        "inactive": inventureInactive
    },
    {
        "active": ohuenoActive,
        "inactive": ohuenoInactive
    },
    {
        "active": polivicActive,
        "inactive": polivicInactive
    },
    {
        "active": voloActive,
        "inactive": voloInactive
    }
];

export default class Partners extends Component {

    getContent() {
        return partners.map(value => (
            <div className="partners__slider-item">
                <img className="partners__slider-item-image partners__slider-item-image--active" src={value.active}/>
                <img className="partners__slider-item-image partners__slider-item-image--inactive" src={value.inactive}/>
            </div>
        ));
    }

    render() {
        return (
            <div className="partners">
                <div className="partners__header">
                    <h2 className="partners__header-background"
                        // style={{transform: `translate(${this.state.partnersLeft}px)`}}
                    >Partners</h2>
                    <h4 className="partners__header-title">Partners</h4>
                </div>
                <Slider
                    className="partners__slider"
                    slidesToShow={3}
                    slidesToScroll={1}
                    arrows={false}
                    infinite={true}
                    autoplay={true}
                    responsive={
                        [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                }
                            }
                        ]
                    }
                    dots={true}>
                    {this.getContent()}
                </Slider>
            </div>
        );
    }
}