import React, {Component} from "react";
import i18n from "i18next";
import "./LanguageSelector.scss"

interface LanguageSelectorState {
    showMenu: boolean
    currentLang: string
}

const LANGUAGES = ["en", "ua", "ru"];

export class LanguageSelector extends Component<{}, LanguageSelectorState> {

    constructor(props: any) {
        super(props);
        this.state = {showMenu: false, currentLang: i18n.language.substr(0, 2)};
    }

    showDropdown = () => {
        this.setState({showMenu: true});
    }

    closeDropdown = () => {
        this.setState({showMenu: false});
    }

    getMenu() {
        return this.state.showMenu ?
            (
                <div className="language-selector__list">
                    {LANGUAGES.filter(value => value !== this.state.currentLang).map(value => (
                        <button className="language-selector__list-item" onClick={() => this.changeLanguage(value)}>
                            {value}
                        </button>
                    ))}
                </div>
            ) : null
    }

    changeLanguage = (language: string) => {
        i18n.changeLanguage(language).then(() => {
            this.setState({currentLang: language, showMenu: false});
        })
    }

    render() {
        return (
            <div className="language-selector" onMouseLeave={this.closeDropdown}>
                <div className="language-selector__button">
                    <button className="language-selector__list-item language-selector__list-item--current"
                            onClick={this.showDropdown}>
                        {this.state.currentLang}
                        <span className={`language-selector__arrow ${this.state.showMenu ? "spinUp" : "spinDown"}`}>
                            <span className="arrow"/>
                        </span>
                    </button>
                </div>
                {this.getMenu()}
            </div>
        );
    }
}