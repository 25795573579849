import React, {Component} from "react";
import {Trans} from "react-i18next";
import {ReactComponent as FooterLogo} from '../../../assets/footer/footer-logo.svg';
import {ReactComponent as InstagramLogo} from '../../../assets/footer/instagram.svg';
import {ReactComponent as FacebookLogo} from '../../../assets/footer/facebook.svg';
import {ReactComponent as YoutubeLogo} from '../../../assets/footer/youtube.svg';
import {ReactComponent as TikTok} from '../../../assets/footer/tiktok.svg';
import {ReactComponent as Email} from '../../../assets/footer/footer-email.svg';
import {ReactComponent as Phone} from '../../../assets/footer/footer-phone.svg';
import {ReactComponent as Medium} from '../../../assets/footer/medium.svg';
import {ReactComponent as Twitter} from '../../../assets/footer/twitter.svg';
import {ReactComponent as LinkedIn} from '../../../assets/footer/linkedin.svg';
import {ReactComponent as Pinterest} from '../../../assets/footer/pinterest.svg';
import madeIn from '../../../assets/footer/made.svg';
import './Footer.scss'
import i18n from "i18next";
import {ContactUsModal} from "../../modal/ContactUs/contactUsModal";
import {Link} from "react-router-dom";

interface FooterInterface {
    isModalOpen: boolean
}

export class Footer extends Component<{}, FooterInterface> {

    constructor(props: any) {
        super(props);
        this.state = {isModalOpen: false};
    }

    render() {
        return (
            <div className="footer" id="contacts">
                <div className="footer__media">
                    <span className="footer__mobile-logo">
                        <FooterLogo/>
                    </span>
                    <span className="footer__media-item phone-link">
                        <Phone/>
                        <a href="tel:+496170961709">+ 38 (099) 527 - 64 - 77</a>
                    </span>
                    <span className="footer__media-item phone-link">
                        <Phone/>
                        <a href="tel:+496170961709">+ 38 (063) 162 - 90 - 00</a>
                    </span>
                    <span className="footer__media-item">
                        <Email/>
                        <a href="mailto:info@ibrand.com">info@ibrandapp.com</a>
                    </span>
                    <div className="footer__media-links">
                        <span className="footer__media-links-item footer__media-links-item--instagram">
                            <a href="https://instagram.com/ibrand.app" target="_blank" rel="noreferrer"><InstagramLogo/></a>
                        </span>
                        <span className="footer__media-links-item footer__media-links-item--facebook">
                            <a href="https://www.facebook.com/iBrandapp" target="_blank" rel="noreferrer">
                                <FacebookLogo/>
                            </a>
                        </span>
                        <span className="footer__media-links-item footer__media-links-item--youtube">
                            <a href="https://www.youtube.com/channel/UCcVVWSuINkJZKczhoJGFVgw" target="_blank"
                               rel="noreferrer">
                                <YoutubeLogo/>
                            </a>
                        </span>
                        <span className="footer__media-links-item footer__media-links-item--tiktok">
                            <a href="src/components/common/Footer/index" target="_blank" rel="noreferrer">
                                <TikTok/>
                            </a>
                        </span>
                        <span className="footer__media-links-item footer__media-links-item--linked-in">
                            <a href="src/components/common/Footer/index" target="_blank" rel="noreferrer">
                                <LinkedIn/>
                            </a>
                        </span>
                        <span className="footer__media-links-item footer__media-links-item--pinterest">
                            <a href="src/components/common/Footer/index" target="_blank" rel="noreferrer">
                                <Pinterest/>
                            </a>
                        </span>
                        <span className="footer__media-links-item footer__media-links-item--twitter">
                            <a href="https://twitter.com/iBrandapp" target="_blank" rel="noreferrer">
                                <Twitter/>
                            </a>
                        </span>
                        <span className="footer__media-links-item footer__media-links-item--medium">
                            <a href="https://medium.com/@iBrandapp" target="_blank" rel="noreferrer">
                                <Medium/>
                            </a>
                        </span>
                    </div>
                    <span className="footer__line-separator"/>
                </div>
                <span className="footer__desktop-logo">
                    <FooterLogo/>
                    <p className="footer__copyright desktop-only">{i18n.t("footer.copyright")}</p>
                </span>
                <div className="footer__links">
                    <Link className="footer__links-item" to="/terms"><Trans i18nKey="footer.terms.text"/></Link>
                    <Link className="footer__links-item" to="/policy"><Trans i18nKey="footer.privacy.text"/></Link>
                    <a className="footer__links-item" onClick={() => this.setState({isModalOpen: true})}
                       href="javascript:void(0);"><Trans i18nKey="footer.contact.text"/></a>
                </div>
                <p className="footer__copyright mobile-only">{i18n.t("footer.copyright")}</p>
                <img src={madeIn} className="footer__made-in"/>
                <ContactUsModal isOpen={this.state.isModalOpen} closeModal={() => this.setState({isModalOpen: false})}/>
            </div>
        );
    }
}
