import React, {Component} from "react";
import opinion from "./../../../assets/offer/opinion/opinion.png";
import "./OpinionLeader.scss"
import {ReactComponent as BrandText} from "../../../assets/common/iBrand-text.svg";
import {ReactComponent as BrandLogo} from "../../../assets/common/iBrandLogo.svg";
import {Trans} from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

interface OpinionLeaderState {
    leftStyle: number
}

export default class OpinionLeader extends Component<{}, OpinionLeaderState> {

    constructor(props: OpinionLeaderState) {
        super(props);

        this.state = {leftStyle: Math.max(Math.max(window.innerWidth / 6) - 0.1 * window.scrollY)};
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        this.setState({leftStyle: Math.max(Math.max(window.innerWidth / 7) - 0.1 * window.scrollY)})
    }

    render() {
        return (
            <div className="offer-component offer-opinion">
                <div className="offer-component__wrapper">
                    <div className="offer-component__li">
                        <span className="square"></span>
                        <div className="thread">
                            <div className="thread-inner"/>
                        </div>
                    </div>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                        <h4 className="offer-component__heading"><Trans i18nKey="offer.opinion.title"/></h4>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true} delay={200}>
                        <p className="offer-component__subheading">
                            <Trans i18nKey="offer.opinion.description"/>
                        </p>
                    </ScrollAnimation>
                </div>
                <img src={opinion} className="offer-opinion__image"/>
                <ScrollAnimation animateIn="animate_visible" animateOnce={true} delay={200}>
                    <div className="offer-opinion__image-content-box">
                        <span className="offer-opinion__image-content-text">
                            <BrandText/>
                        </span>
                        <span className="offer-opinion__image-content-logo">
                            <BrandLogo/>
                        </span>
                        <img className="offer-opinion__image-content-image" src={opinion} alt="Producer"/>
                    </div>
                </ScrollAnimation>
                <h2 className="offer-opinion__background desktop-only" style={{
                    transform: `translateX(${this.state.leftStyle}px)`
                }}>Opinion</h2>
            </div>
        );
    }
}