import React, {Component} from "react";
import Slider from "react-slick";
import {Trans} from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from "react-animate-on-scroll";
import second from "../../../assets/home/guarantee/first.jpeg"
import first from "../../../assets/home/guarantee/second.jpeg"
import third from "../../../assets/home/guarantee/third.jpeg"
import "./Guarantee.scss"

export class Guarantee extends Component {
    
    getContent() {
        return [
            (
                <ScrollAnimation key="blue" className="guarantee__slider-card" animateOnce={true}
                                 style={{backgroundImage: `url(${first})`}} animateIn="animate__fadeInTopLeft">
                    <div className="thread">
                        <div className="thread-inner"/>
                    </div>
                    <h3 className="guarantee__slider-card-title">
                        <Trans i18nKey="guarantee.first.title"/>
                    </h3>
                    <p className="guarantee__slider-card-coming">Coming soon</p>
                    <span className="guarantee__slider-card-row"/>
                    <p className="guarantee__slider-card-text">
                        <Trans i18nKey="guarantee.first.text"/>
                    </p>
                    <div className="guarantee__slider-card-color guarantee__slider-card--blue"/>
                </ScrollAnimation>
            ),
            (
                <ScrollAnimation animateIn="animate__fadeInDown" key="violet" className="guarantee__slider-card"
                                 style={{backgroundImage: `url(${second})`}} animateOnce={true}>
                    <div className="thread">
                        <div className="thread-inner"/>
                    </div>
                    <h3 className="guarantee__slider-card-title">
                        <Trans i18nKey="guarantee.second.title"/>
                    </h3>
                    <p className="guarantee__slider-card-coming">Coming soon</p>
                    <span className="guarantee__slider-card-row"/>
                    <p className="guarantee__slider-card-text">
                        <Trans i18nKey="guarantee.second.text"/>
                    </p>
                    <div className="guarantee__slider-card-color guarantee__slider-card--violet"/>
                </ScrollAnimation>
            ),
            (
                <ScrollAnimation animateIn="animate__fadeInBottomRight" key="peach" className="guarantee__slider-card"
                                 style={{backgroundImage: `url(${third})`}} animateOnce={true}>
                    <div className="thread">
                        <div className="thread-inner"/>
                    </div>
                    <h3 className="guarantee__slider-card-title">
                        <Trans i18nKey="guarantee.third.title"/>
                    </h3>
                    <p className="guarantee__slider-card-coming">Coming soon</p>
                    <span className="guarantee__slider-card-row"/>
                    <p className="guarantee__slider-card-text">
                        <Trans i18nKey="guarantee.third.text"/>
                    </p>
                    <div className="guarantee__slider-card-color guarantee__slider-card--peach"/>
                </ScrollAnimation>
            )
        ];
    }

    getMobileContent() {
        return [
            (
                <>
                    <div key="blue" className="guarantee__slider-card" style={{backgroundImage: `url(${first})`}}>
                        <h3 className="guarantee__slider-card-title">
                            <Trans i18nKey="guarantee.first.title"/>
                        </h3>
                        <p className="guarantee__slider-card-coming">Coming soon</p>
                        <span className="guarantee__slider-card-row"/>
                        <p className="guarantee__slider-card-text">
                            <Trans i18nKey="guarantee.first.text"/>
                        </p>
                        <div className="guarantee__slider-card-color guarantee__slider-card--blue"/>
                    </div>
                </>
            ),
            (
                <>
                    <div key="violet" className="guarantee__slider-card" style={{backgroundImage: `url(${second})`}}>
                        <h3 className="guarantee__slider-card-title">
                            <Trans i18nKey="guarantee.second.title"/>
                        </h3>
                        <p className="guarantee__slider-card-coming">Coming soon</p>
                        <span className="guarantee__slider-card-row"/>
                        <p className="guarantee__slider-card-text">
                            <Trans i18nKey="guarantee.second.text"/>
                        </p>
                        <div className="guarantee__slider-card-color guarantee__slider-card--violet"/>
                    </div>
                </>
            ),
            (
                <>
                    <div key="peach" className="guarantee__slider-card" style={{backgroundImage: `url(${third})`}}>
                        <h3 className="guarantee__slider-card-title">
                            <Trans i18nKey="guarantee.third.title"/>
                        </h3>
                        <p className="guarantee__slider-card-coming">Coming soon</p>
                        <span className="guarantee__slider-card-row"/>
                        <p className="guarantee__slider-card-text">
                            <Trans i18nKey="guarantee.third.text"/>
                        </p>
                        <div className="guarantee__slider-card-color guarantee__slider-card--peach"/>
                    </div>
                </>
            )
        ];
    }

    render() {
        return (
            <div className="guarantee" id="guarantee">
                <div className="guarantee__header">
                    <h2 className="guarantee__header-background">Guarantee</h2>
                    <h2 className="guarantee__header-background desktop-only">deal</h2>
                    <h4 className="guarantee__header-title">Guarantee</h4>
                </div>
                <div className="guarantee__content-desktop">
                    {this.getContent()}
                </div>
                <div className="guarantee__content-mobile">
                    <Slider
                        className="guarantee__slider"
                        slidesToShow={1}
                        dotsClass="guarantee__slider-dots"
                        slidesToScroll={1}
                        infinite={false}
                        dots={true}>
                        {this.getMobileContent()}
                    </Slider>
                </div>
            </div>
        );
    }
}