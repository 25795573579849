import React, {Component, Fragment} from "react";
import Slider from "react-slick";
import {Trans} from "react-i18next";
import done from "../../../assets/home/roadmap/done.svg";
import inProgress from "../../../assets/home/roadmap/in-progress.svg";
import toDo from "../../../assets/home/roadmap/todo.svg";
import "./Roadmap.scss"


interface RoadmapState {
    roadmapLeft: number
}

export default class Roadmap extends Component<{}, RoadmapState> {


    constructor(props: any) {
        super(props);
        this.state = {roadmapLeft: 0}
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.innerWidth < 768) {
            this.setState({roadmapLeft: Math.max(Math.max(window.innerWidth / 2) - 0.03 * window.scrollY)})
        } else {
            this.setState({roadmapLeft: Math.max(Math.max(window.innerWidth / 3) - 0.07 * window.scrollY)})
        }
    }


    getList(index: number) {
        let set = [1, 2, 3, 4, 5];

        if (index === 1) {
            set.push(6);
        }

        return set.map(item => (
            <li className="roadmap__card-list-item">
                <span>
                    <Trans i18nKey={`home.card.${index}.${item}`}/>
                </span>
            </li>
        ));
    }

    getIcon(index: number) {
        switch (index) {
            case 1:
                return done;
            case 2:
                return inProgress;
            case 3:
                return toDo;
        }
    }

    getContent() {
        return [1, 2, 3].map(index => (
            <Fragment>
                <h6 className="roadmap__card-heading">
                    <Trans i18nKey={`home.card.${index}`}/>
                </h6>
                <ul className="roadmap__card-list" style={{
                    listStyleImage: `url("${this.getIcon(index)}")`
                }}>
                    {this.getList(index)}
                </ul>
            </Fragment>
        ));
    }

    render() {
        return (
            <div className="roadmap">
                <div className="roadmap__header">
                    <h2 className="roadmap__header-background"
                        style={{transform: `translate(${this.state.roadmapLeft}px)`}}>Roadmap</h2>
                    <h4 className="roadmap__header-title">Roadmap</h4>
                </div>
                <div className="roadmap__content">
                    <Slider
                        className="roadmap__slider"
                        slidesToShow={3}
                        dotsClass="roadmap__slider-dots"
                        slidesToScroll={0}
                        arrows={false}
                        infinite={false}
                        responsive={
                            [
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                    }
                                }
                            ]
                        }
                        dots={true}>
                        {this.getContent()}
                    </Slider>
                </div>
            </div>
        );
    }
}