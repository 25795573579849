import React, {Component} from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {ReactComponent as BrandText} from "../../../assets/common/iBrand-text.svg";
import {ReactComponent as BrandLogo} from "../../../assets/common/iBrandLogo.svg";
import "./Introduction.scss"

interface IntroductionProperties {
    title: string
    description?: string
    backgroundText: string
    cardText: string
}

interface IntroductionState {
    visible: string
    leftStyle: number
}

export default class CommonIntroduction extends Component<IntroductionProperties, IntroductionState> {

    constructor(props: IntroductionProperties) {
        super(props);

        this.state = {
            visible: '',
            leftStyle: window.innerWidth < 768 ? Math.max(Math.max(window.innerWidth / 5) - 0.05 * window.scrollY) : Math.max(Math.max(window.innerWidth / 9) - 0.1 * window.scrollY)
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.innerWidth < 768) {
            this.setState({leftStyle: Math.max(Math.max(window.innerWidth / 5) - 0.05 * window.scrollY)})
        } else {
            this.setState({leftStyle: Math.max(Math.max(window.innerWidth / 9) - 0.1 * window.scrollY)})
        }
    }

    getDescription() {
        if (!this.props.description) {
            return
        }

        return (
            <ScrollAnimation animateIn="animate_visible" delay={200} animateOnce={true}>
                <p className="introduction__data-text">{this.props.description}</p>
            </ScrollAnimation>
        );
    }

    render() {
        return (
            <div className={`introduction ${this.state.visible}`}>
                <div className="introduction__data">
                    <h2 className="introduction__data-background" style={{
                        transform: `translateX(${this.state.leftStyle}px)`
                    }}>{this.props.backgroundText}</h2>
                    <div className="introduction__data-content">
                        <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                            <h4 className="introduction__data-title">{this.props.title}</h4>
                        </ScrollAnimation>
                        {this.getDescription()}
                    </div>
                    <div className="thread mobile-only">
                        <div className="thread-inner"/>
                    </div>
                </div>
                <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                    <div className="introduction__card">
                    <span className="introduction__card-text">
                        <BrandText/>
                    </span>
                        <span className="introduction__card-logo">
                        <BrandLogo/>
                    </span>
                        <div className="introduction__card-message">
                            <h4 className="introduction__card-message-text">{this.props.cardText}</h4>
                        </div>
                    </div>
                </ScrollAnimation>
                <div className="thread desktop-only">
                    <div className="thread-inner"/>
                </div>
            </div>
        );
    }
}