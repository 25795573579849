import React, { Component } from "react";
import CommonIntroduction from "../../common/Introduction";
import "./OfferIntroduction.scss"
import i18n from "i18next";

export default class OfferIntroduction extends Component {

    render() {
        return <CommonIntroduction title={i18n.t("offer.introduction.title")} cardText="Brand Face" backgroundText="Offer"/>;
    }
}