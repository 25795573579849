import React, {Component} from "react";
import appStore from "../../../assets/common/app-store.png";
import appStoreHover from "../../../assets/common/app-store-hover.png";
import playMarket from "../../../assets/common/play-market.png";
import playMarketHover from "../../../assets/common/play-market-hover.png";
import "./Credo.scss"
import {ComingSoonModal} from "../../modal/ComingSoon";
import credoImage from "../../../assets/home/credo/Credo-Image.png"

interface CredoProps {
    mainHeading: string
    subHeading: string
    background?: string
    mainText?: string
    additionalText?: string
}

interface CredoState {
    playMarketHover: boolean
    appStoreHover: boolean
    credoLeft: number
    showModal: boolean
}

export class CommonCredo extends Component<CredoProps, CredoState> {

    constructor(props: CredoProps) {
        super(props);

        this.state = {
            playMarketHover: false,
            appStoreHover: false,
            showModal: false,
            credoLeft: 0
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }


    handleScroll = () => {
        if (window.innerWidth < 768) {
            this.setState({
                credoLeft: Math.max(window.innerWidth - 0.05 * window.scrollY),

            })
        } else {
            this.setState({
                credoLeft: Math.max(Math.max(window.innerWidth / 1.4) - 0.15 * window.scrollY),
            })
        }
    }

    getAdditionalSection() {
        if (!this.props.mainText && !this.props.additionalText) {
            return
        }

        return (
            <div className="credo__yourself-wrapper">
                <h5 className="credo__by">{this.props.mainText}</h5>
                <div className="credo__yourself-highlight-wrapper">
                    <span className="credo__yourself-highlight"/>
                    <h6>{this.props.additionalText}</h6>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="credo">
                <div className="credo__wrapper">
                    <h2 className="credo__make">{this.props.mainHeading}</h2>
                    <h1 className="credo__yourself">{this.props.subHeading}</h1>
                    {this.getAdditionalSection()}
                </div>
                <div className="credo__image-wrapper">
                    <img className="credo__image-main" src={credoImage}
                         alt="Make yourself by yourself"/>
                    <h2 className="credo__image-background"
                        style={{transform: `rotate(90deg) translateX(${this.state.credoLeft}px)`}}
                    >{this.props.background}</h2>
                </div>
                <div className="credo__download-links">
                    <a className="credo__download-link" target="_blank" rel="noreferrer"
                       href="https://apps.apple.com/ua/app/ibrand-models-castings-events/id1608781339"
                       onMouseOver={() => this.setState({appStoreHover: true})}
                       onMouseLeave={() => this.setState({appStoreHover: false})}>
                        <img src={this.state.appStoreHover ? appStoreHover : appStore} alt="Download from App Store"/>
                    </a>
                    <a className="credo__download-link" target="_blank" rel="noreferrer"
                       href="https://play.google.com/store/apps/details?id=com.ibrandapp"
                       onMouseOver={() => this.setState({playMarketHover: true})}
                       onMouseLeave={() => this.setState({playMarketHover: false})}>
                        <img src={this.state.playMarketHover ? playMarketHover : playMarket}
                             alt="Download from Play Market"/>
                    </a>
                </div>
                <ComingSoonModal isOpen={this.state.showModal} closeModal={() => this.setState({showModal: false})}/>
            </div>
        );
    }
}