import {Component} from "react";
import brandText from "../../../assets/home/top/iBrand-text.png";
import {Model, ModelCard} from "./ModelCard";
import {ProducerCard, Producer} from "./ProducerCard";
import axios from "axios";
import "./Top.scss"
import "./Card.scss"
import ScrollAnimation from "react-animate-on-scroll";

interface TopState {
    type: boolean
    models?: Model[]
    producers?: Producer[]
}

export class Top extends Component<{}, TopState> {

    constructor(props: any) {
        super(props);

        this.state = {type: true, models: [], producers: []};
    }

    componentDidMount() {
        this.fetchModelsData().then(data => this.setState({models: data}));
        this.fetchProducersData().then(data => this.setState({producers: data}));
    }

    fetchModelsData = async () => {
        try {
            const result = await axios.get("/top/models?count=3");
            // const result = await axios.get("https://ibrandapp.com/top/models?count=3");
            return result.data as Model[];
        } catch (error) {
            console.error(error);
        }
    }

    async fetchProducersData() {
        try {
            const result = await axios.get("/top/customers?count=3");
            // const result = await axios.get("https://ibrandapp.com/top/customers?count=3");
            return result.data as Producer[];
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        if (!this.state.models && !this.state.producers) {
            return null;
        }

        return (
            <div className="top" id="top">
                <div className="top__background"/>
                <div className="top__content">
                    <div className="top__heading">
                        <h2 className="top__heading-main">TOP 3</h2>
                        <div className="top__heading-secondary-wrapper">
                            <h5 className="top__heading-secondary-text">Faces</h5>
                            <img className="top__heading-secondary-logo" src={brandText} alt="Brand text"/>
                        </div>
                    </div>
                    <div className="top__switcher">
                        <button
                            className={`top__switcher-button ${this.state.type ? "top__switcher-button--active" : ""}`}
                            onClick={() => this.setState({type: true})}>Models
                        </button>
                        <button
                            className={`top__switcher-button ${!this.state.type ? "top__switcher-button--active" : ""}`}
                            onClick={() => this.setState({type: false})}>Producers
                        </button>
                    </div>
                    <div className={`top__lists ${this.state.type ? "top__lists--models" : "top__lists--producers"}`}>
                        <div className="top__models">
                            <div className="top__models-title top__models-title--container">
                                <span className="top__models-title top__models-title--capital">M</span>
                                <span className="top__models-title top__models-title--lower">odel</span>
                            </div>
                            <ScrollAnimation animateIn="slided" animateOnce={true}>
                                <div className="top__models-list">
                                    {this.state.models?.map(model => <ModelCard key={model.id} {...model} />)}
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="top__producers">
                            <div className="top__producers-title top__producers-title--container">
                                <span className="top__producers-title top__producers-title--capital">P</span>
                                <span className="top__producers-title top__producers-title--lower">roducer</span>
                            </div>
                            <ScrollAnimation animateIn="slided" className="top__producers-list" animateOnce={true}>
                                <div className="top__producers-list">
                                    {this.state.producers?.map(producer => <ProducerCard
                                        key={producer.id} {...producer} />)}
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}