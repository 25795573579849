import React, {Component} from "react";
import {Trans} from "react-i18next";
import {ReactComponent as HeaderLogo} from '../../../assets/header/header-logo.svg';
import appStore from '../../../assets/header/app-store.svg';
import appStoreHover from '../../../assets/header/app-store-hover.svg';
import playMarket from '../../../assets/header/play-market.svg';
import playMarketHover from '../../../assets/header/play-market-hover.svg';
import {ReactComponent as Burger} from '../../../assets/header/burger.svg';
import {ReactComponent as Cross} from '../../../assets/header/cross.svg';
import {ReactComponent as BrandLogo} from '../../../assets/footer/footer-logo.svg';
import './Header.scss';
import {LanguageSelector} from "../../common/LanguageSelector";
import {Link} from "react-router-dom";

interface HeaderState {
    isMenuOpened: boolean
    appStoreIconHovered: boolean
    playMarketIconHovered: boolean
    currentAnchor: string
    isOnTop: boolean
}

export class OfferHeader extends Component<{}, HeaderState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isMenuOpened: false,
            appStoreIconHovered: false,
            playMarketIconHovered: false,
            currentAnchor: "",
            isOnTop: document.documentElement.scrollTop === 0
        };
    }

    componentDidMount() {
        window.onscroll = () => {
            this.setState({isOnTop: document.documentElement.scrollTop === 0});
        };
    }

    getMenuIcon() {
        return this.state.isMenuOpened ? <Cross/> : <Burger/>
    }

    onClick = () => {
        this.setState({isMenuOpened: !this.state.isMenuOpened});
    }

    onAppStoreIconHovered = () => {
        this.setState({appStoreIconHovered: !this.state.appStoreIconHovered});
    }

    onPlayMarketIconHovered = () => {
        this.setState({playMarketIconHovered: !this.state.playMarketIconHovered});
    }

    render() {
        return (
            <header className={`offer-header ${this.state.isOnTop ? "header--top" : ""}`}>
                <div className="offer-header__main-logo">
                    <Link to="/">
                        <HeaderLogo/>
                    </Link>
                </div>
                <div className="offer-header__navigation">
                    <Link to="/">
                        <Trans i18nKey="offer.header.home"/>
                    </Link>
                </div>
                <div className="offer-header__actions">
                    <div className="offer-header__actions-common">
                        <span className="offer-header__actions-icon" onMouseEnter={this.onAppStoreIconHovered}
                              onMouseLeave={this.onAppStoreIconHovered}>
                            <img src={this.state.appStoreIconHovered ? appStoreHover : appStore}
                                 alt="Download from App Store"/>
                        </span>
                        <span className="offer-header__actions-icon" onMouseEnter={this.onPlayMarketIconHovered}
                              onMouseLeave={this.onPlayMarketIconHovered}>
                            <img src={this.state.playMarketIconHovered ? playMarketHover : playMarket}
                                 alt="Download from Play Market"/>
                        </span>
                        <LanguageSelector/>
                    </div>
                    <span className="offer-header__actions-icon menu-icon" onClick={this.onClick}>{this.getMenuIcon()}</span>
                </div>
                <div className={`offer-header__hamburger-menu ${this.state.isMenuOpened ? "show" : "close"}`}>
                    <ul className="offer-header__hamburger-menu-list">
                        <Link to="/">
                            <Trans i18nKey="offer.header.home"/>
                        </Link>
                    </ul>
                    <div className="offer-header__hamburger-menu-logo">
                        <BrandLogo/>
                    </div>
                </div>
            </header>
        );
    }
}