import {Component} from "react";
import Modal from "react-modal";
import {Field, Form} from 'react-final-form'
import "./ContactUs.scss"
import {Trans} from "react-i18next";
import i18n from "i18next";
import {ReactComponent as Close} from "../../../assets/footer/close.svg";
import axios from "axios";

interface ContactUsProps {
    isOpen: boolean
    closeModal: () => void
}

export class ContactUsModal extends Component<ContactUsProps> {

    onSubmit = async (values: any) => {
        const result = await axios.post("https://ibrandapp.com/api/feedback/contact-us", values);
        console.log(result);
    }

    required = (value: string) => (value ? undefined : i18n.t("contact.us.empty.error"));

    email = (value: string) => (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ? undefined : i18n.t("contact.us.email.error"));

    render() {
        const customStyles = {
            overlay: {zIndex: 1000}
        };

        return (
            <Modal
                isOpen={this.props.isOpen}
                style={customStyles}
                closeTimeoutMS={500}
                className="contact-us-modal"
                contentLabel="Contact Us Modal">
                <h4 className="contact-us-title"><Trans i18nKey="contact.us.title"/></h4>
                <button className="contact-us-button-close" onClick={() => this.props.closeModal()}>
                    <Close/>
                </button>
                <Form
                    onSubmit={this.onSubmit}
                    // validate={validate}
                    render={({handleSubmit}) => (
                        <form onSubmit={handleSubmit} className="contact-us-form">
                            <Field name="firstName" component="input" validate={this.required}>
                                {({input, meta}) => (
                                    <>
                                        <input
                                            className={`contact-us-form-field ${meta.error && meta.touched && "contact-us-form-field--error"}`} {...input}
                                            placeholder={i18n.t("contact.us.firstname")}/>
                                        {meta.error && meta.touched &&
                                        <span className="contact-us-form-field-error-message">{meta.error}</span>}
                                    </>
                                )}
                            </Field>
                            <Field name="lastName" component="input" validate={this.required}>
                                {({input, meta}) => (
                                    <>
                                        <input
                                            className={`contact-us-form-field ${meta.error && meta.touched && "contact-us-form-field--error"}`} {...input}
                                            placeholder={i18n.t("contact.us.lastname")}/>
                                        {meta.error && meta.touched &&
                                        <span className="contact-us-form-field-error-message">{meta.error}</span>}
                                    </>
                                )}
                            </Field>
                            <Field name="email" component="input" validate={this.email}>
                                {({input, meta}) => (
                                    <>
                                        <input
                                            className={`contact-us-form-field ${meta.error && meta.touched && "contact-us-form-field--error"}`} {...input}
                                            placeholder={i18n.t("contact.us.email")}/>
                                        {meta.error && meta.touched &&
                                        <span className="contact-us-form-field-error-message">{meta.error}</span>}
                                    </>
                                )}
                            </Field>
                            <Field name="text" component="textarea" validate={this.required}>

                                {({input, meta}) => (
                                    <>
                                            <textarea
                                                className={`contact-us-form-field-area ${meta.error && meta.touched && "contact-us-form-field-area--error"}`} {...input}
                                                placeholder={i18n.t("contact.us.message")}/>
                                        {meta.error && meta.touched &&
                                        <span className="contact-us-form-field-error-message">{meta.error}</span>}
                                    </>
                                )}

                            </Field>
                            <button className="contact-us-form-submit" type="submit">
                                <Trans i18nKey="contact.us.submit"/>
                            </button>
                        </form>
                    )}
                />
                <p className="contact-us-disclaimer">
                    <Trans i18nKey="contact.us.policy.text"/>
                    <a className="contact-us-disclaimer-link" href={i18n.t("footer.privacy.link")}>
                        <p><Trans i18nKey="contact.us.policy.link"/></p>
                    </a>
                </p>
            </Modal>
        );
    }
}