import React, {Component} from "react";
import crown from "./../../../assets/offer/ambassador/crown.png";
import profile from "./../../../assets/offer/ambassador/profile.png";
import events from "./../../../assets/offer/ambassador/events.png";
import "./OfferAmbassador.scss";
import {Trans} from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

export default class OfferAmbassador extends Component {
    render() {
        return (
            <div className="offer-component offer-ambassador">
                <div className="offer-component__wrapper">
                    <div className="offer-component__li">
                        <span className="square"></span>
                        <div className="thread">
                            <div className="thread-inner"/>
                        </div>
                    </div>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                        <h4 className="offer-component__heading"><Trans i18nKey="offer.ambassador.title"/></h4>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true} delay={200}>
                        <p className="offer-component__subheading">
                            <Trans i18nKey="offer.ambassador.description"/>
                        </p>
                    </ScrollAnimation>
                </div>
                <div className="offer-ambassador__content">
                    <img src={profile} className="offer-ambassador__image offer-ambassador__image--first"/>
                    <img src={events} className="offer-ambassador__image offer-ambassador__image--second"/>
                </div>
                <img src={crown} className="offer-ambassador__background"/>
            </div>
        );
    }
}