import React, {Component} from "react";
import Introduction from "../Introduction";
import ForModels from "../ForModels";
import {Producer} from "../Producer";
import {Major} from "../Major";
import {About} from "../About";
import {Guarantee} from "../Guarantee";
import {Top} from "../Top";
import "animate.css/animate.min.css";
import {Header} from "../../common/Header";
import {Footer} from "../../common/Footer";
import Ambassador from "../Ambassador";
import './Container.scss'
import Credo from "../Credo";
import Roadmap from "../Roadmap";
import Investments from "../Investments";
import Partners from "../Partners";

interface ContainerProps {
    images: any
}

export class HomeContainer extends Component<ContainerProps> {

    render() {
        return (
            <div className="container">
                <Header/>
                <Major images={this.props.images}/>
                <Introduction/>
                <ForModels images={this.props.images}/>
                <Producer images={this.props.images}/>
                <About images={this.props.images}/>
                <Guarantee/>
                <Investments/>
                <Ambassador/>
                <Top/>
                <Roadmap/>
                <Partners/>
                <Credo/>
                <Footer/>
            </div>
        );
    }
}