import React, {Component} from "react";
import verified from "./../../../assets/offer/verified/verified.png"
import "./Verified.scss"
import {ReactComponent as BrandText} from "../../../assets/common/iBrand-text.svg";
import {Trans} from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

interface VerifiedState {
    leftPadding: number
}

export default class Verified extends Component<{}, VerifiedState> {

    constructor(props: VerifiedState) {
        super(props);

        this.state = {leftPadding: Math.max(Math.max(window.innerWidth / 6) - 0.1 * window.scrollY)};
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        this.setState({leftPadding: Math.max(Math.max(window.innerWidth * 0.60) - 0.1 * window.scrollY)})
    }

    render() {
        return (
            <div className="offer-verified offer-component">
                <div className="offer-component__wrapper">
                    <div className="offer-component__li">
                        <span className="square"></span>
                        <div className="thread">
                            <div className="thread-inner"/>
                        </div>
                    </div>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true}>
                        <h4 className="offer-component__heading"><Trans i18nKey="offer.verified.title"/></h4>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="animate_visible" animateOnce={true} delay={200}>
                        <p className="offer-component__subheading">
                            <Trans i18nKey="offer.verified.description"/>
                        </p>
                    </ScrollAnimation>
                    <span className="offer-verified__image-content-text">
                        <BrandText/>
                    </span>
                </div>
                <div className="offer-verified__content">
                    <img src={verified} className="offer-verified__image"/>
                    <h1 className="offer-verified__text">Verified</h1>
                </div>
                <h2 className="offer-verified__background desktop-only" style={{
                    transform: `translateX(${this.state.leftPadding}px)`
                }}>Verified</h2>
            </div>
        );
    }
}